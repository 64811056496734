import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../../store/dashboard/reporForm/reportForm.actions';
import Button from "../../ui/CustomButton/Button";
import moment from 'moment';

const ReportButtons = (props) => {
  const {report} = props;

  return report.edit ?
    <>
      {moment().diff(report.date, 'days')<20 &&
      <Button
        variant='text'
        size='sm'
        onClick={() => props.showConfirmation(true)}
        className={style.btnDelete}
      >
        Ištrinti
      </Button>
      }
      <Button
        variant='contained'
        size='sm'
        onClick={() => props.updateReport(report)}
        className={style.btnEdit}
      >
        Išsaugoti
      </Button>
    </> :
    <Button
      variant='text'
      size='sm'
      onClick={() => props.toggleEditReport(report._id)}
      className={style.btnEdit}
    >
      Redaguoti
    </Button>


};

ReportButtons.propTypes = {
  report: PropTypes.object,
  showConfirmation:PropTypes.func
};

export default connect(null, actions)(ReportButtons);