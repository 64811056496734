import React, {useState} from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';
import RichTextEditor from 'react-rte';
import Icon from "../Icon/Icon";
import './customStyle.scss';

const TextEditor = props => {


  const [edit, toggleEdit] = useState(true);
  const [localValue, setValue] = useState(RichTextEditor.createEmptyValue());

  const loadForm = () => {
    setValue(RichTextEditor.createValueFromString(props.value || '', 'html'))
  };

  const onChange = (value) => {
    setValue(value);
    props.onInputChange({
      target: {
        value: value.toString('html'),
        index: props.index,
        name: props.name
      }
    })
  };

  const renderContent = () => {

    if (edit || !props.value || props.value.length < 12) return (
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={localValue}
        className={style.editor}
        style={{border: '1px solid red'}}
        onChange={onChange}
      />
    );

    return <div
      className={style.content}
      dangerouslySetInnerHTML={props.value && {__html: props.value}}/>;

  };

  return (
    <div className={[props.className, style.textEditor].join(' ')}>

      <div className={style.leftSide}>
        {props.moveUp &&
        <Icon icon='chevron-up'
              onClick={props.moveUp}
              className={[style.icon, style.up].join(' ')}/>}
        {props.moveDown &&
        <Icon icon='chevron-down'
              onClick={props.moveDown}
              className={[style.icon, style.down].join(' ')}/>}
      </div>

      {renderContent()}

      <div className={style.rightSide}>
        {edit ?
          (props.value && props.value.length > 11 &&
            <Icon icon='save'
                  className={[style.icon, style.save].join(' ')}
                  onClick={async () => {
                    toggleEdit(false);
                    props.save();
                  }}/>) :
          <Icon icon='edit'
                onClick={() => {
                  toggleEdit(true);
                  loadForm()
                }}
                className={[style.icon, style.edit].join(' ')}/>
        }
        {props.onDelete &&
        <Icon icon='trash'
              onClick={props.onDelete}
              className={[style.icon, style.del].join(' ')}/>}
      </div>

    </div>
  )
}

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Paragrafas', style: 'unstyled'},
    {label: 'Antraste', style: 'header-two'},
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'Sąrašas', style: 'unordered-list-item'},
  ]
};


TextEditor.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  index: PropTypes.number,
  value: PropTypes.string,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  onDelete: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  moveUp: PropTypes.func,
  moveDown: PropTypes.func,
  save: PropTypes.func,
};
TextEditor.defaultProps = {
  save: ()=>{console.log('save')}
};

export default TextEditor;