import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';

const Loader = (props) => {
  if(props.loading){
    return (
      <div className={[style.lds_ripple, props.className].join(' ')}>
        <div/>
        <div/>
      </div>
    )
  }else{
    return null
  }
};

Loader.propTypes = {
  loading:PropTypes.bool.isRequired,
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Loader;