import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const SelectedProducts = props => {

  const {report} = props

  const selectedOptions = report.products.map((option, i) => {
    return (
      <li onClick={() => props.removeProduct(option)} key={i}>{option}</li>
    )
  });
  return (
    <div className={[style.SelectedProducts, props.className].join(' ')}>
      {selectedOptions}
    </div>
  );
};

SelectedProducts.propTypes = {
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  report:PropTypes.object.isRequired,
  removeProduct:PropTypes.func.isRequired
};

export default SelectedProducts;