import * as types from '../../types';
import axios from 'axios';
import {showError} from '../../utils';
import {saveReminder} from "../../reminders/reminders.actions";

export const selectCompany = (company) => {
  return {
    type: types.REPORT_SELECT_COMPANY,
    company
  }
};

export const selectType = (value) => {
  return {
    type: types.REPORT_SELECT_TYPE,
    value
  }
};

export const onInputChange = (e, reportId) => {
  const {value, name} = e.target;
  return {
    type: types.REPORT_INPUT_CHANGE,
    value,
    name,
    reportId
  }
};

export const onDaySelect = (day) => {
  return {
    type: types.REPORT_DAY_SELECT,
    day
  }
};

export const goBack = (where) => {
  return {
    type: types.REPORT_GO_BACK,
    where
  }
};

export const saveReport = (report, history) => {
  if (report.loading) {
    return {type: types.REPORT_ERROR, errors: {}}
  }
  const errors = {};
  if (!report.comment) errors.comment = 'Neirašytas komentaras';
  if (
    (report.type === 'sale' || report.type === 'offer')
    && report.state === 'canceled'
    && !report.reason
  ) {
    errors.reason = 'Neirašyta priežastis';
  }
  if (report.weekday === 5 || report.weekday === 6) errors.weekday = 'Nepasirinkta diena';
  if ((report.type === 'sale' || report.type === 'offer') && !report.price) errors.price = 'Neirašyta kaina';

  if (Object.keys(errors).length !== 0) {
    return {
      type: types.REPORT_ERROR,
      errors
    }
  }

  return async function (dispatch, getState) {
    dispatch({
      type: types.REPORT_LOADING
    });
    try {
      // send report json data
      const {data} = await axios.post('/api/reports', report);

      if (report.files.length) {
        const formData = new FormData();
        formData.append('_id', data._id);
        formData.append('companyName', report.company.name);
        report.files.forEach(file => formData.append('files', file));

        await axios.post('/api/reports/upload', formData);
      }
      const {newReminder} = getState().reminders;
      if (newReminder.data) {
        await axios.post('/api/reminders', {...newReminder.data, report: data._id})
        dispatch({
          type: types.SAVE_REMINDER_DONE,
        })
      }
      // Send files
      setTimeout(() => {
        dispatch({
          type: types.REPORT_SAVED,
          report: data,
          user: getState().auth.user
        });
        history.push('/dashboard/reports/week')
      }, 200);

    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const addFile = (files) => {
  return {
    type: types.REPORT_FILE_UPLOAD,
    files
  }
};

export const removeFile = (index) => {
  return {
    type: types.REPORT_REMOVE_FILE,
    index
  }
};

export const addProduct = (e) => {
  return {
    type: types.REPORT_ADD_PRODUCT,
    product: e.target.value
  }
};

export const removeProduct = (product) => {
  return {
    type: types.REPORT_REMOVE_PRODUCT,
    product
  }
};

export const clearReportForm = () => {
  return {
    type: types.REPORT_FORM_CLEARED,
  }
};

export const showReport = (report) => {
  return {
    type: types.REPORT_SHOW_REPORT,
    report
  }
};

export const toggleEditReport = (reportId) => {
  return {
    type: types.REPORT_TOGGLE_EDIT,
    reportId
  }
};


export const fetchReport = (id) => {
  return async dispatch => {
    try {
      const {data} = await axios.get('/api/reports/single/' + id);
      dispatch({
        type: types.REPORT_FETCH_SINGLE,
        data,
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const updateReport = (report) => {
  const errors = {};
  if (
    (report.type === 'sale' || report.type === 'offer')
    && report.state === 'canceled'
    && !report.reason
  ) {
    errors.reason = 'Neirašyta priežastis';
  }
  if (!report.comment) errors.comment = 'Neirašytas komentaras';
  if ((report.type === 'sale' || report.type === 'offer') && !report.price) errors.price = 'Neirašyta kaina';
  if (Object.keys(errors).length !== 0) {
    return {
      type: types.REPORT_ERROR,
      errors
    }
  }
  return async (dispatch) => {
    try {
      await axios.put('/api/reports/single', report);
      dispatch({
        type: types.REPORT_UPDATED,
        report
      });
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const deleteFile = (file, reportId) => {
  return async (dispatch) => {
    console.log(file, reportId);
    try {
      await axios.delete(`/api/reports/delete-file/${reportId}/${file}`);
      dispatch({
        type: types.REPORT_REMOVE_FILE,
        file
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const updateFile = (files, report) => {
  return async (dispatch) => {
    try {
      const fd = new FormData();
      fd.append('_id', report._id);
      fd.append('companyName', report.company.name);
      fd.append('files', files[0]);
      const {data} = await axios.post('/api/reports/upload', fd);
      dispatch({
        type: types.REPORT_FILE_UPLOAD,
        files: data
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};


