import * as types from '../../types';

const initialState = {
  loading: false,
  errors: {},
  list: [],
  comments: [],
  user: null,
  week: 0,
  selectedReport: null,
  stopScroll: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMMENTS_FETCH_DONE : {
      if (!action.data) return state;
      return {...state, comments: [...state.comments, action.data]}
    }
    case types.REPORT_SAVED : {
      const {_id, firstName, lastName} = action.user;
      return {...state, user: {_id, firstName, lastName}};
    }
    case types.LOGOUT_USER :
      return initialState;
    case types.REPORTS_SELECT_USER :
      return {...initialState, user: action.user};
    case types.REPORTS_LOADING :
      return {...state, loading: true};
    case types.REPORTS_ERROR :
      return {...initialState, errors: action.errors};
    case types.AUTH_USER || types.LOGIN_FORM_SUCCESS : {
      const {_id, firstName, lastName, role} = action.user;
      if (role === 'sales') return {...state, user: {_id, firstName, lastName}}
      return state;
    }
    case types.LOGIN_FORM_SUCCESS : {
      const {_id, firstName, lastName, role} = action.user;
      if (role === 'sales') return {...state, user: {_id, firstName, lastName}}
      return state;
    }
    case types.REPORT_UPDATED : {
      const list = state.list.map(week => {
        return week.map(report => {
          if (report._id === action.report._id) return {...action.report, edit: false};
          return report
        })
      });
      return {...state, list}
    }
    case types.REPORT_DELETED : {
      if (!state.list || !state.list.length) return state;
      const list = state.list.map(week => {
        return week.filter(report => {
          return report._id !== action.id
        })
      });
      return {...state, list}
    }
    case types.REPORTS_CLEAR :
      return initialState;
    case types.REPORTS_LOAD_MORE :
      return {
        ...state,
        list: [...state.list, action.data],
        loading: false,
        week: state.week + 1
      };
    case types.REPORTS_FETCHED_WEEKLY_REPORTS :
      return {...state, list: action.data, week: 2, loading: false};
    default :
      return state
  }
}
