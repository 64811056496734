import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/companies/form/companyForm.actions';
import style from './index.module.scss';

const FetchDataButton = (props) => {
  if (props.form.errors.data) {
    return (
      <div className={style.notFound}>
        {props.form.errors.data}
      </div>
    )
  }
  return (
    <div className={style.btnAuto}
         onClick={props.fetchCompanyData}>
      Užpildyti duomenis iš rekvizitai.lt
      <div className={style.popup}>
        Įmonės duomenys nuskaitomi iš rekvizitai.lt pagal pavadinimą.
        <br/>
        Taip pat galima įkelti įmonės nuorodą, taip visad suveiks
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    form: state.companies.companyForm
  }
};
export default connect(mapStateToProps, actions)(FetchDataButton);