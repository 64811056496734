// ID's must be unique!

export default [
  {
    link: null,
    height: 0,
    icon: 'dashboard',
    id: 4,
    name: {lt:'Suvestinė', en:'Dashboard'},
    sublinks: [
      {
        link: '/dashboard/new-report',
        name: {lt:'Naujas įrašas', en:'New record'}
      },
      {
        link: `/dashboard/reports/week`,
        name: {lt:'Savaitės įmonių įrašai', en:'Weekly records'}
      },
      {
        link: `/dashboard/offers`,
        name: {lt:'Komerciniai pasiūlymai', en:'Offers'}
      }
    ]
  },
  {
    link: null,
    height: 0,
    icon: 'companies',
    id: 5,
    name: {lt:'Įmonės',en:'Companies'},
    sublinks: [
      {
        name: {lt:'Sąrašas',en:'Companies list'},
        link: '/companies/list'
      },
      {
        name: {lt:'Įtraukti naują',en:'Create new'},
        link: '/companies/create'
      },
    ]
  },
  {
    link: '/notifications',
    height: 0,
    icon: 'exclamation',
    id: 113,
    name: {lt:'Pranešimai', en:'Notifications'},
    sublinks: []
  },
  {
    link: '/settings',
    height: 0,
    icon: 'settings',
    id: 3,
    name: {lt:'Nustatymai', en:'Settings'},
    sublinks: []
  },
]

