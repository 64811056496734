import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
// Store
import * as actions from '../../../store/rent/rent.actions';
// Components
import Card from "../../../components/ui/Card";
import Select, {Option} from "../../../components/ui/CustomSelect/Select";
// Style
import style from './index.module.scss';
import Button from "../../../components/ui/CustomButton/Button";

const StatusCard = (props) => {
  const {openStatusCard, onInputChange, updateItem, user} = props;
  const {statusIsVisible, card,isSaving} = props.rent;
  return (
    <Card
      backdrop={true}
      closeModal={()=>openStatusCard({})}
      color='secondary'
      title='Keisti statusą'
      visible={statusIsVisible}
      zIndex={30}
    >
      <Select
        onChange={onInputChange}
        name='state'
        className={style.input}
        value={card.state}
        color='primary'
        label='Būsena'
        size='md'
        variant='standard'
      >
        <Option value='available' label='Laisvas'/>
        <Option value='rented' label='Išnuomotas'/>
        <Option value='broken' label='Sugędes'/>
        <Option value='reserved' label='Rezervuotas'/>
      </Select>
      <div className={style.label}>Nuo</div>
      <p className={style.date}>{moment().format('YYYY-MM-DD')}</p>
      <div className={style.label}>Iki</div>
      <p className={style.date}>{moment().add(1, 'days').format('YYYY-MM-DD')}</p>
      <Button
        color='primary'
        onClick={()=>updateItem(user)}
        className={style.button}
        loading={isSaving}
      >
        Išsaugoti
      </Button>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    rent:state.rent,
    user:state.auth.user
  }
};

export default connect(mapStateToProps, actions)(StatusCard);
