import React, {useState} from 'react';
import Table from "../../components/ui/Table/Table";
import moment from 'moment';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from "../../components/ui/Button/Button";
import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {changeReminderDate, changeReminderStatus} from "../../store/reminders/reminders.actions";
import ConfirmationModal from "../../components/ui/ConfirmationModal/ConfirmationModal";
import Modal from "../../components/ui/Modal/Modal";
import {Calendar} from "react-calendar";

const cx = classNames.bind(styles);

export const Row = (props) => {
  const {isFuture} = props;
  const [withModal, showModal] = useState(false);
  const [withDateChange, showDateChange] = useState(false);
  const [date, onDateChange] = useState(new Date());
  const {comment, notifyAt, company, report, _id} = props.reminder;
  const [error, setError] = useState();
  const dispatch = useDispatch()

  const confirm = () => {
    dispatch(changeReminderStatus({id: _id, hasSeen: true, isFuture}))
  }

  const openDateChangeModal = () => {
    showDateChange(true);
    onDateChange(notifyAt);
  }

  const changeDate = () => {
    const isAfter = moment(date).isAfter(Date.now())
    if(!isAfter){
      return setError('Pasirink teisingą datą')
    }else{
      setError(undefined);
    }
    dispatch(changeReminderDate({id: _id, isFuture, notifyAt:date}));
    showDateChange(false);
  }

  return (
    <div className={cx('reminder')}>
      <Modal visible={withDateChange} hideModal={() => showDateChange(false)}>
        <Calendar locale='lt' onChange={onDateChange} value={date}/>
        {error && <div className={cx('error')}>{error}</div>}
        <div className={cx('buttons')}>
          <Button type='primary' onClick={() => showDateChange(false)} className={cx('button')}>
            Atšaukti
          </Button>
          <Button type='primary' onClick={changeDate} className={cx('button')}>
            Patvirtinti
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        visible={withModal}
        hideModal={() => showModal(false)}
        confirm={confirm}
        loading={false}
      >
        Uždaryti priminimą
      </ConfirmationModal>
      <div className={cx('date')}>{moment(notifyAt).format('YYYY-MM-DD')}</div>
      <Table className={cx('table')}>
        {company &&
          <Link to={`/company/${company._id}/info`} className={cx('company')}>
            <div>{company.name}</div>
          </Link>}
        {report &&
          <div className={cx('report')}>
            <div>{report.comment}</div>
          </div>}
        <div className={cx('row')}>
          <div className={cx('comment')}>{comment}</div>
          <div className={cx('buttons')}>
            <Button onClick={openDateChangeModal} type='secondary' className={cx('button', 'button--outlined')}>
              Perkelti datą
            </Button>
            <Button type='secondary' className={cx('button')} onClick={() => showModal(true)}>
              Pažymeti kaip ivyktytą
            </Button>
          </div>
        </div>
      </Table>
    </div>
  );
};
