import React from 'react';
import Heading1 from "../../components/ui/Heading1/Heading1";
import {useDispatch, useSelector} from "react-redux";
import {getFutureReminders} from "../../store/reminders/reminders.actions";
import Loader from "../../components/ui/Loader/Loader";
import {Row} from "./Row";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import Button from "../../components/ui/Button/Button";

const cx = classNames.bind(styles);

export const Reminders = () => {
  const dispatch = useDispatch()
  const {reminders, futureReminders} = useSelector((state) => state.reminders);

  const renderReminders = () => {
    if (!reminders.data) return <Loader loading={true}/>
    return reminders.data.map((reminder) => <Row reminder={reminder} key={reminder._id}/>)
  }

  const renderFutureReminders = () => {
    if (!futureReminders.data) return null;
    return futureReminders.data.map((reminder) => <Row isFuture reminder={reminder} key={reminder._id}/>)
  }

  return (
    <div className={cx('container')}>
      <Heading1>Priminimai</Heading1>
      {renderReminders()}
      {futureReminders.data && <div><Heading1 className={cx('heading')}>Būsimi priminimai</Heading1></div>}
      {renderFutureReminders()}
      {!futureReminders.data &&
        <Button className={cx('future')} type='primary' onClick={() => dispatch(getFutureReminders())}>
          Rodyti būsimus
        </Button>}
    </div>
  );
};
