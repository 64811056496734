import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as actions from '../../../store/dashboard/reporForm/reportForm.actions';
import Select, {Option} from "../../ui/CustomSelect/Select";

const ReportStatus = (props) => {
  const report = {...props.report, errors: props.report.errors || {}};

  if (report.type === 'meeting' || report.type === 'phone') return null;

  let textAreaStyle = {height: '50px'};
  if (report.resume && report.resume.length) {
    textAreaStyle = {height: report.resume.length / 45 * 25 + 'px'}
  }
  return (
    <div className={style.status}>
      <div className={style.state}>
          <span>
            Busena:
          </span>
        <Select
          onChange={(e) => props.onInputChange(e, report._id)}
          variant='standard'
          size='sm'
          className={style.select}
          name='state'
          value={report.state}
          color='secondary'
        >
          <Option value='active' label='Aktyvus'/>
          <Option value='sold' label='Laimetas'/>
          <Option value='canceled' label='Atsauktas'/>
        </Select>
      </div>
      {report.state === 'canceled' &&
      <div className={style.reason}>
          <span>
            Priežastis:
          </span>
        <Select
          onChange={(e) => props.onInputChange(e, report._id)}
          variant='standard'
          size='sm'
          className={style.select}
          name='reason'
          error={report.errors.reason}
          value={report.reason}
          color='secondary'
        >
          <Option value='out of stock' label='Prekės nėra sandėlyje'/>
          <Option value='delivery terms' label='Per ilgas pristatymo terminas'/>
          <Option value='price' label='Didesnė kaina nei konkurentų'/>
          <Option value='no funding' label='Įmonė negavo finansavimo'/>
          <Option value='outside financing' label='Finansavimo konkurentas'/>
          <Option value='bought used machine' label='Įsigijo dėvėtą techniką'/>
          <Option value='not buying' label='Nusprende nebepirkti'/>
        </Select>
      </div>
      }
      {report.edit ?
        <fieldset className={style.editResume}>
          <textarea
            style={textAreaStyle}
            name='resume'
            onChange={(e) => props.onInputChange(e, report._id)}
            className={style.textArea}
            value={report.resume}
          />
        </fieldset> :
        <p className={style.desc}>
          {report.resume}
        </p>
      }
    </div>
  )
};

ReportStatus.propTypes = {
  report: PropTypes.object.isRequired
};

export default connect(null, actions)(ReportStatus);
