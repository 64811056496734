import React from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import {NavLink, Link, withRouter} from 'react-router-dom';
import Icon from '../../../components/ui/Icon/Icon';
import AnimateHeight from 'react-animate-height';

/**
 * Menu Item with subLinks
 */
const MenuItem = (props) => {
  const {item, lang} = props;
  const sublinks = item.sublinks.map((link, i) => {
    return (
      <NavLink data-menu activeClassName={style.active} className={style.sublink} to={link.link} key={i}>
        {link.name[lang]}
      </NavLink>
    )
  });

  // Render expanded side menu
  if (!props.sideMenu.closed) return (
    <div className={style.item_open}>
      {item.link ?
        <Link className={style.name} to={item.link}>
          <Icon icon={item.icon} className={style.icon}/>
          <span>{item.name[lang]}</span>
        </Link> :
        <h4 className={style.name} onClick={() => props.toggleSublinks(item.id)}>
          <Icon icon={item.icon} className={style.icon}/>
          <span>{item.name[lang]}</span>
          <Icon icon='chevron-left'
                style={{transform: item.height === 0 ? '' : 'rotate(-90deg)'}}
                className={style.chevron}/>
        </h4>
      }
      {sublinks.length > 0 &&
      <AnimateHeight height={item.height} duration={200}>
        {sublinks}
      </AnimateHeight>
      }
    </div>
  );
  // Render closed side menu
  if (props.sideMenu.closed) return (
    <div className={style.item_closed}>
      {item.link ?
        <Link className={style.name} to={item.link}>
          <Icon icon={item.icon} className={style.icon}/>
        </Link> :
        <React.Fragment>
          <h4 className={style.name} onClick={() => props.toggleSublinks(item.id)}>
            <Icon icon={item.icon} className={style.icon}/>
          </h4>
          { item.height ==='auto' &&
            <div className={style.sublinks}>
              <h4 className={style.name}>{item.name[lang]}</h4>
              {sublinks}
            </div>
          }
        </React.Fragment>
      }
    </div>
  )
};


const mapStateToProps = state => {
  return {
    sideMenu: state.sideMenu,
    lang:state.settings.lang,
  }
};
export default withRouter(connect(mapStateToProps)(MenuItem));
