import * as types from '../../types';
import moment from 'moment';

const initialState= {
  firstName:'',
  lastName:'',
  email:'',
  status:'',
  role:'',
  phone:'',
  image:'',
  desc:'',
  year:'',
  month:'',
  day:'',
  errors:{},
  confirmCode:'',
  password:'',
};



export default (state=initialState,action)=>{
  switch (action.type){
    case types.USER_FORM_INPUT_CHANGE:
      return {...state, [action.name]:action.value};
    case types.USER_FORM_ERROR :
      return {...state, errors: action.errors, loading:false};
    case types.USER_FORM_LOADING:
      return {...state,loading:true, errors:{}};
    case types.USER_CREATED :
      return initialState;
    case types.USER_PASSWORD_RESET :
      return {...state, resetSuccess:true};
    case types.USER_LOAD_FORM:{
      const user  = action.user;
      user.year= moment(user.birthday).year();
      user.month= moment(user.birthday).format('MM');
      user.day= moment(user.birthday).format('DD');
      return {...initialState, ...user};
    }
    case types.USER_UPLOAD_IMAGE :
      return {...state, image:action.image};
    case types.USER_CLEAR_FORM :
      return initialState;
    default : return state
  }
}