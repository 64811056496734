import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import style from './index.module.scss';
import * as actions from '../../../store/dashboard/weekly/weeklyReports.actions';
import {fetchUsers} from '../../../store/users/users.actions';
import Select from "../../../components/ui/Select/Select";
import Loader from "../../../components/ui/Loader/Loader";
import ReportTable from "../../../components/WeeklyReportTable/WeeklyReportTable";
import debounce from "lodash/debounce";
import Button from "../../../components/ui/Button/Button";
import Heading1 from "../../../components/ui/Heading1/Heading1";
import ReportCard from '../../../components/ReportCard';
import t from '../../../utils/translations';

const WeeklyReports = (props) => {
  const {reports, auth, lang} = props;

  const selectUser = (user) => {
    props.selectUser(user);
  };

  useEffect(() => {
    if (!reports.user) return;
    props.fetchFirstWeeks(reports.user._id);
    // eslint-disable-next-line
  }, [reports.user]);

  // Infinite scroll with debounce
  const scroll = debounce(() => {
    if (props.reports.stopScroll) return;
    const body = document.body,
      html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.scrollY + window.innerHeight * 1.6 > height && !props.reports.loading) {
      props.fetchWeekReports(reports.week + 1, reports.user._id);
    }
  }, 500);


  // Enable infinite scroll on page load
  useEffect(() => {
    if (!props.users) props.fetchUsers();
    document.addEventListener('scroll', scroll);
    return () => {
      document.removeEventListener('scroll', scroll);
    }
    // eslint-disable-next-line
  }, [scroll]);


  if (!props.users) return <Loader loading/>;


  const users = props.users && props.users.map(user => {
    const {_id, firstName, lastName} = user;
    return {label: `${firstName} ${lastName}`, value: {_id, firstName, lastName}}
  });

  /** Render Reports Table */
  const renderReports = () => {
    if (!reports.list) return;
    return reports.list.map((weekReports, n) => {
      return (
        <ReportTable
          fetchComments={props.fetchComments}
          report={weekReports}
          deleteReport={props.deleteReport}
          key={n}
          auth={props.auth.user}
          week={n}
          userId={reports.user._id}
        />
      )
    });
  };

  /** Render selected Report popup on the screen */
  const renderSelectedReport = () => {
    if (!props.report._id) return;
    return (
      <ReportCard position='absolute' report={props.report}/>
    )
  };

  return (
    <div className={style.weeklyReport}>
      <div className={style.report}>
        <Heading1 className={style.heading}>
          {auth.user.role === 'admin' ?
            <Select
              name='user'
              value={reports.user}
              placeholder='Pasirinkti darbuotoją'
              className={style.user}
              onChange={(e) => selectUser(e.target.value)}
              options={users}
              capitalize
              type='primary-heading'
            /> :
            <span>
              {props.auth.user.firstName} {props.auth.user.lastName}
            </span>
          }
          <span className={style.title}>{t.weekly_report[lang]}</span>
        </Heading1>

        {reports.user && reports.user._id === auth.user._id &&
        <Button
          className={style.newRecord}
          type='primary'
          onClick={() => props.history.push('/dashboard/new-report')}>
          {t.new_record[lang]}
        </Button>
        }

        {renderReports()}
        {renderSelectedReport()}

      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    reports: state.dashboard.weeklyReports,
    report: state.dashboard.reportForm,
    users: state.users.usersList,
    auth: state.auth,
    lang:state.settings.lang
  }
};
export default connect(mapStateToProps, {
  ...actions,
  fetchUsers
})(WeeklyReports);
