import * as types from '../../types';

const initialState = {
  errors: {},
  name: '',
  type: '',
  image: '',
  desc: '',
  code: '',
  region: '',
  address: '',
  link:'',
  categories: [],
  contacts: [],
  reports: null,
  stars: 1,
  user: '',
  status:'',
  confirmCode: '',
  loading: false,
  deleting: false,
};
const newContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_FETCH_DATA_ERROR :
      return {...state, errors:action.errors};
    case types.COMPANY_FETCHED :
      return {...state, loading: false, ...action.data};
    case types.COMPANY_FORM_ERROR:
      return {...state, errors: action.errors};
    case types.COMPANY_FORM_LOADING:
      return {...state, loading: true, errors: {}};
    case types.COMPANY_FORM_INPUT_CHANGE :
      return {...state, [action.name]: action.value};
    case types.COMPANY_REMOVE_CONTACT : {
      const contacts = state.contacts.filter((c, i) => i !== action.index);
      return {...state, contacts}
    }
    case types.COMPANY_ASSIGN_DEFAULT_USER:{
      const {firstName, lastName, _id} = action.user;
      return {...state, user:{_id, firstName, lastName}}
    }
    case types.COMPANY_FETCH_DATA : {
      const {code, address, companyType, region,name, link} =action;
      return {...state, code, address, link, type:companyType, region, name}
    }
    case types.REPORTS_FETCHED_COMPANY_REPORTS :
      return {...state, reports: action.data};
    case types.REPORTS_CLEAR :
      return {...state, reports: null};
    case types.COMPANY_SAVED :
      return initialState;
    case types.COMPANY_UPLOAD_IMAGE:
      return {...state, image: action.image};
    case types.COMPANY_ADD_CONTACT:
      return {...state, contacts: [...state.contacts, newContact]};
    case types.COMPANY_ADD_CATEGORY :
      if (!action.name) return state;
      return {...state, categories: [...state.categories, action.name]};
    case types.COMPANY_REMOVE_CATEGORY : {
      const categories = state.categories.filter((cat) => cat !== action.name);
      return {...state, categories};
    }
    case types.COMPANY_LOAD_FORM :
      return {...initialState, ...action.company};
    case types.COMPANY_CLEAR_FORM :
      return initialState;
    case types.COMPANY_CONTACT_INPUT_CHANGE: {
      const contacts = [...state.contacts].map((c, i) => {
        if (i === action.index) {
          return {...c, [action.name]: action.value}
        } else return c
      });
      return {...state, contacts}
    }
    default :
      return state
  }
}