import React from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import Button from "../../../../components/ui/Button/Button";
import Heading2 from "../../../../components/ui/Heading2/Heading2";
import Loader from "../../../../components/ui/Loader/Loader";
import Icon from "../../../../components/ui/Icon/Icon";

const CompanyInfo = props => {
  const {company, history} = props;

  if (!props.company.name) return <Loader loading={true}/>;

  const categories = company.categories.map((cat, i) => {
    return (
      <li key={i}>{cat}</li>
    )
  });

  const renderStars = () => {
    const stars = [];
    for (let x = 1; x <= 5; x++) {
      stars.push(
        <Icon icon={x <= company.stars ? 'star-solid' : 'star-regular'}
              key={x}
              className={x <= company.stars ? style.star_solid : style.star_regular}/>
      )
    }
    return stars
  };


  const renderContacts = () => {
    if (props.user.role !== 'admin' && props.user._id !== company.user._id) return null;
    const contacts = company.contacts && company.contacts.map((c, i) => {
      return (
        <div className={style.contact} key={i}>
          <p className={style.name}>{c.firstName}</p>
          <p className={style.name}>{c.lastName}</p>
          <p>{c.position}</p>
          <p>{c.phone}</p>
          <p>{c.email}</p>
        </div>
      )
    });
    return (
      <>
        <Heading2>Kontaktiniai asmenys</Heading2>
        <div className={style.contacts}>
          {contacts}
        </div>
      </>
    )
  };

  return (
    <div className={style.info}>
      <Heading2>
        <strong className={style.companyName}>
          Įmonės duomenys
          {company.link && <a href={company.link} target='_blank'>Rekvizitai.lt</a>}
        </strong>
        <span className={style.stars}>{renderStars()}</span>
      </Heading2>
      {company.status && company.status!=='active' &&
      <div className={style.status}>{company.status} įmonė</div>
      }
      {company.code && <h3>Įmonės kodas: {company.code}</h3>}
      <h3>{company.region}</h3>
      <h3>{company.address}</h3>
      <ul>{categories}</ul>
      {renderContacts()}
      {company.desc && <Heading2>Kita</Heading2>}
      <p className={style.desc}>{company.desc}</p>
      {(props.user.role === 'admin' || props.user._id === company.user._id) &&
      <Button onClick={() => history.push(`/update-company/${company._id}`)}
              className={style.button}
              type='primary-outline'>
        Redaguoti
      </Button>
      }

    </div>
  );
};

const mapStateToProps = state => {
  return {
    company: state.companies.companyForm,
    user: state.auth.user,
  }
};
export default connect(mapStateToProps)(CompanyInfo);