import * as types from '../../types';

export const initialState = {
  loading: false,
  newPassword: '',
  oldPassword: '',
  confirmPassword: '',
  message: '',
  errors: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PASSWORD_INPUT_CHANGE :
      return {...state, [action.field]:action.value};
    case types.PASSWORD_FORM_LOADING :
      return {...state,loading: true};
    case types.PASSWORD_FORM_ERROR :
      return {...state, errors: action.errors, loading:false}
    case types.PASSWORD_FORM_SUBMIT :
      return {...initialState, message:action.message};
    default :
      return state
  }
}