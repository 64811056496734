import React from 'react';
import Dropzone from "react-dropzone";
import style from "./index.module.scss";
import Icon from "../ui/Icon/Icon";
import PropTypes from 'prop-types';

const FileUploads = (props) => {
  const {report} = props;

  const availableTypes=['offer', 'meeting','sale'];

  if(!availableTypes.includes(report.type)) return null;
  return (
      <>
        <h5>Prisegti dokumentai</h5>
        <Dropzone onDrop={props.uploadFile}>
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className={style.files}>
              <input {...getInputProps()} />
              {report.files.map((file, i) => {
                return (
                  <p key={i} onClick={(e) => {
                    e.stopPropagation();
                  }}>
                    <Icon icon='trash' className={style.trash} onClick={(e) => {
                      e.stopPropagation();
                      props.removeFile(i)
                    }}/>
                    {file.name}
                  </p>
                )
              })}
              <strong>Įkelti dokumentus</strong>
            </div>
          )}
        </Dropzone>
      </>
  );
};

FileUploads.propTypes={
  report:PropTypes.object.isRequired,
  removeFile:PropTypes.func.isRequired,
  uploadFile:PropTypes.func.isRequired,
}

export default FileUploads;