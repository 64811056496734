import React from 'react';
import style from './index.module.scss';
import Heading2 from "../Heading2/Heading2";
import Button from "../Button/Button";
import {connect} from 'react-redux';
import * as actions from '../../../store/notifications/errors/errorMessage.actions';

const ErrorModal = (props) => {
  if(!props.error) return null;
  return (
    <React.Fragment>
      <div className={style.backdrop} onClick={props.hideUnexpectedError}/>
      <div className={style.modal}>
        <Heading2 className={style.heading}>Nenumatyta Klaida</Heading2>
        <p className={style.desc}>{props.error}</p>
        <Button type='primary'
                className={style.button}
                onClick={props.hideUnexpectedError}>
          Uždaryti
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    error:state.notifications.errorMessage
  }
};

export default connect(mapStateToProps, actions)(ErrorModal);