import * as types from '../../types';

export default (state=null,action)=>{
  switch (action.type){
    case types.REGIONS_FETCHED :
      return action.data.sort((a,b)=>{
        return a.replace('š', 's')>b.replace('š', 's') ? 1: -1});
    case types.REGION_DELETED :
      return state.filter((item)=>item!==action.name);
    case types.REGION_CREATED :
      return [...state, action.region];
    default : return state
  }
}