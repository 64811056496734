import axios from 'axios';
import * as types from '../../types';
import {showError} from '../../utils';

export const fetchNotifications = () => {
  return async dispatch => {
    try {
      const {data} = await axios.get('/api/notifications');
      dispatch({
        type: types.NOTIFICATIONS_FETCH,
        data
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const deleteNotification = (id) => {
  return async dispatch => {
    try {
      await axios.delete('/api/notifications/' + id);
      dispatch({
        type: types.NOTIFICATIONS_DELETED,
        id
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};