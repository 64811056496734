import * as types from '../../types';


export default (state=null,action)=>{
    switch (action.type){
      case types.CATEGORIES_FETCHED :
        return action.data.sort((a,b)=>{
          return a.replace('š', 's')>b.replace('š', 's') ? 1: -1});
      case types.CATEGORY_DELETED :
        return state.filter((item)=>item!==action.category);
      case types.CATEGORY_CREATED :
        return [...state, action.category];
      default : return state
    }
}