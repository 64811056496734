export const userRoles = {
  admin: 'Administratorius',
  sales: 'Pardavimai',
  rend: 'Nuoma',
  manager: 'Vadybininkas'
};

export const userRolesOptions = Object.entries(userRoles)
  .map(arr => ({label: arr[1], value: arr[0]}));

export const userStatus = {
  working: 'Dirbantis',
  inactive: 'Nebedirbantis',
  absent: 'Susirgęs',
};

export const userStatusOptions = Object.entries(userStatus)
  .map(arr => ({label: arr[1], value: arr[0]}));