import * as types from '../../types';

export default (state = false, action) => {
  switch (action.type) {
    case types.SHOW_UNEXPECTED_ERROR :
      return action.data;
    case types.HIDE_UNEXPECTED_ERROR :
      return false;
    case types.LOGOUT_USER :
      return false;
    default :
      return state
  }
}