// Login
export const LOGIN_FORM_INPUT_CHANGE = 'LOGIN_FORM_INPUT_CHANGE';
export const LOGIN_FORM_LOADING = 'LOGIN_FORM_LOADING';
export const LOGIN_FORM_ERROR = 'LOGIN_FORM_ERROR';
export const LOGIN_FORM_SUCCESS = 'LOGIN_FORM_SUCCESS';
export const LOGIN_FORM_CLEAR_ERROR = 'LOGIN_FORM_CLEAR_ERROR';

// Auth
export const AUTH_USER = 'AUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';

// Layout
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const LOAD_MENU_SETTINGS = 'LOAD_MENU_SETTINGS';

export const SHOW_UNEXPECTED_ERROR = 'SHOW_UNEXPECTED_ERROR';
export const HIDE_UNEXPECTED_ERROR = 'HIDE_UNEXPECTED_ERROR';

export const NOTIFICATIONS_FETCH = 'NOTIFICATIONS_FETCH';
export const NOTIFICATIONS_DELETED = 'NOTIFICATIONS_DELETED';
// Lang

// Profile
export const TOGGLE_EDIT_PROFILE = 'TOGGLE_EDIT_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_INPUT_CHANGE = 'PROFILE_INPUT_CHANGE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_IMAGE_CHANGE = 'PROFILE_IMAGE_CHANGE';

// Password
export const PASSWORD_INPUT_CHANGE = 'PASSWORD_INPUT_CHANGE';
export const PASSWORD_FORM_LOADING = 'PASSWORD_FORM_LOADING';
export const PASSWORD_FORM_ERROR = 'PASSWORD_FORM_ERROR';
export const PASSWORD_FORM_SUBMIT = 'PASSWORD_FORM_SUBMIT';

// Lang
export const CHANGE_LANG = 'CHANGE_LANG';

// Utils
export const CLEAR_INFO_MESSAGE = 'CLEAR_INFO_MESSAGE';

// Categories
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';
export const CATEGORY_DELETED = 'CATEGORY_DELETED';
export const CATEGORY_CREATED = 'CATEGORY_CREATED';

// Regions
export const REGIONS_FETCHED = 'REGIONS_FETCHED';
export const REGION_DELETED = 'REGION_DELETED';
export const REGION_CREATED = 'REGION_CREATED';

// Users
export const USERS_FETCHED = 'USERS_FETCHED';
export const USER_FORM_INPUT_CHANGE = 'USER_FORM_INPUT_CHANGE';
export const USER_FORM_ERROR = 'USER_FORM_ERROR';
export const USER_FORM_LOADING = 'USER_FORM_LOADING';
export const USER_LOAD_FORM = 'LOAD_USER_FORM';
export const USER_CLEAR_FORM = 'USER_CLEAR_FORM';
export const USER_CREATED = 'USER_CREATED';
export const USER_UPLOAD_IMAGE = 'USER_UPLOAD_IMAGE';
export const USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
export const USER_DELETED = 'USER_DELETED';

// Company
export const COMPANY_FORM_INPUT_CHANGE = 'COMPANY_FORM_INPUT_CHANGE';
export const COMPANY_UPLOAD_IMAGE = 'COMPANY_UPLOAD_IMAGE';
export const COMPANY_ADD_CATEGORY = 'COMPANY_ADD_CATEGORY';
export const COMPANY_REMOVE_CATEGORY = 'COMPANY_REMOVE__CATEGORY';
export const COMPANY_ADD_CONTACT = 'COMPANY_ADD_CONTACT';
export const COMPANY_REMOVE_CONTACT = 'COMPANY_REMOVE_CONTACT';
export const COMPANY_CONTACT_INPUT_CHANGE = 'COMPANY_CONTACT_INPUT_CHANGE';
export const COMPANY_FORM_ERROR = 'COMPANY_FORM_ERROR';
export const COMPANY_FORM_LOADING = 'COMPANY_FORM_LOADING';
export const COMPANY_LOAD_FORM = 'COMPANY_LOAD_FORM';
export const COMPANY_CLEAR_FORM = 'COMPANY_CLEAR_FORM';
export const COMPANY_DELETED = 'COMPANY_DELETED';
export const COMPANY_SAVED = 'COMPANY_SAVED';
export const COMPANY_FETCHED = 'COMPANY_FETCHED';
export const COMPANY_FETCH_DATA_ERROR = 'COMPANY_FETCH_DATA_ERROR';
export const COMPANY_FETCH_DATA = 'COMPANY_FETCH_DATA';
export const COMPANY_ASSIGN_DEFAULT_USER = 'COMPANY_ASSIGN_DEFAULT_USER';
export const COMPANIES_HIDE_OLD = 'COMPANIES_HIDE_OLD';

// Companies
export const COMPANIES_LOADING = 'COMPANIES_LOADING';
export const COMPANIES_FETCHED = 'COMPANIES_FETCHED';
export const COMPANIES_INPUT_CHANGE = 'COMPANIES_INPUT_CHANGE';
export const COMPANIES_TOGGLE_ADVANCED_SEARCH = 'COMPANIES_TOGGLE_ADVANCED_SEARCH';
export const COMPANIES_CLEAR_FILTER = 'COMPANIES_CLEAR_FILTER';

// Report
export const REPORT_SELECT_COMPANY = 'REPORT_SELECT_COMPANY';
export const REPORT_SELECT_TYPE = 'REPORT_SELECT_TYPE';
export const REPORT_INPUT_CHANGE = 'REPORT_INPUT_CHANGE';
export const REPORT_DAY_SELECT = 'REPORT_DAY_SELECT';
export const REPORT_GO_BACK = 'REPORT_GO_BACK';
export const REPORT_SAVED = 'REPORT_SAVED';
export const REPORT_LOADING = 'REPORT_LOADING';
export const REPORT_ERROR = 'REPORT_ERROR';
export const REPORT_FILE_UPLOAD = 'REPORT_FILE_UPLOAD';
export const REPORT_REMOVE_FILE = 'REPORT_REMOVE_FILE';
export const REPORT_UPLOAD_PROGRESS = 'REPORT_UPLOAD_PROGRESS';
export const REPORT_ADD_PRODUCT = 'REPORT_ADD_PRODUCT';
export const REPORT_REMOVE_PRODUCT = 'REPORT_REMOVE_PRODUCT';
export const REPORT_DELETED = 'REPORT_DELETED';
export const REPORT_FORM_CLEARED = 'REPORT_FORM_CLEARED';
export const REPORT_FETCH_SINGLE = 'REPORT_FETCH_SINGLE';
export const REPORT_UPDATED = 'REPORT_UPDATED';
export const REPORT_SHOW_REPORT = 'REPORT_SHOW_REPORT';
export const REPORT_TOGGLE_EDIT = 'REPORT_TOGGLE_EDIT';

// Reports
export const REPORTS_LOADING = 'REPORTS_LOADING';
export const REPORTS_ERROR = 'REPORTS_ERROR';
export const REPORTS_LOAD_MORE = 'REPORTS_LOAD_MORE';
export const REPORTS_FETCHED_WEEKLY_REPORTS = 'REPORTS_FETCHED_WEEKLY_REPORTS';
export const REPORTS_FETCHED_COMPANY_REPORTS = 'REPORTS_FETCHED_COMPANY_REPORTS';
export const REPORTS_SELECT_USER = 'REPORTS_SELECT_USER';
export const REPORTS_CLEAR = 'REPORTS_CLEAR';

// Offers
export const OFFERS_LOADING = 'OFFERS_LOADING';
export const OFFERS_FETCHED = 'OFFERS_FETCHED';
export const OFFERS_INPUT_CHANGE = 'OFFERS_INPUT_CHANGE';
export const OFFERS_SEARCH_INPUT_CHANGE = 'OFFERS_SEARCH_INPUT_CHANGE';
export const OFFERS_CLEAR_FILTER = 'OFFERS_CLEAR_FILTER';
export const OFFERS_SET_SKIP = 'OFFERS_SET_SKIP';

// Rent
export const RENT_TOGGLE_MENU = 'RENT_TOGGLE_MENU';
export const RENT_TOGGLE_NEW_ITEM_CARD = 'RENT_TOGGLE_NEW_ITEM_CARD';
export const RENT_ON_CARD_INPUT_CHANGE = 'RENT_ON_CARD_INPUT_CHANGE';
export const RENT_ON_SEARCH_INPUT_CHANGE = 'RENT_ON_SEARCH_INPUT_CHANGE';
export const RENT_SAVE_ITEM_STARTED = 'RENT_SAVE_ITEM_STARTED';
export const RENT_SAVE_ITEM_DONE = 'RENT_SAVE_ITEM_DONE';
export const RENT_SAVE_ITEM_FAILED = 'RENT_SAVE_ITEM_FAILED';
export const RENT_FETCH_ITEMS_STARTED = 'RENT_FETCH_ITEMS_STARTED';
export const RENT_FETCH_ITEMS_FAILED = 'RENT_FETCH_ITEMS_FAILED';
export const RENT_FETCH_ITEMS_DONE = 'RENT_FETCH_ITEMS_DONE';
export const RENT_OPEN_STATUS_CARD = 'RENT_OPEN_STATUS_CARD'
export const RENT_UPDATE_ITEM_DONE = 'RENT_UPDATE_ITEM_DONE'

// Statistics
export const FETCH_STATISTICS_STARTED = 'FETCH_STATISTICS_STARTED';
export const FETCH_STATISTICS_DONE = 'FETCH_STATISTICS_DONE';
export const FETCH_STATISTICS_FAILED = 'FETCH_STATISTICS_FAILED';
export const CLEAR_STATISTICS = 'CLEAR_STATISTICS';

// Comments
export const COMMENTS_FETCH_STARTED = 'FETCH_COMMENTS_STARTED';
export const COMMENTS_FETCH_DONE = 'FETCH_COMMENTS_DONE';
export const COMMENTS_FETCH_FAILED = 'FETCH_COMMENTS_FAILED';
export const COMMENTS_CREATE_STARTED = 'FETCH_COMMENTS_STARTED';
export const COMMENTS_CREATE_DONE = 'FETCH_COMMENTS_DONE';
export const COMMENTS_CREATE_FAILED = 'FETCH_COMMENTS_FAILED';

// Reminders
export const SHOW_REMINDER = 'SHOW_REMINDER';
export const SAVE_REMINDER = 'SAVE_REMINDER';
export const SAVE_REMINDER_DONE = 'SAVE_REMINDER_DONE';
export const SAVE_REMINDER_FAILED = 'SAVE_REMINDER_FAILED';
export const FETCH_REMINDERS_FAILED = 'FETCH_REMINDERS_FAILED';
export const FETCH_REMINDERS_DONE = 'FETCH_REMINDERS_DONE';
export const FETCH_REMINDERS = 'FETCH_REMINDERS';
export const ADD_REMINDER = 'ADD_REMINDER';
export const FETCH_FUTURE_REMINDER_DONE = 'FETCH_FUTURE_REMINDER_DONE';
export const FETCH_FUTURE_REMINDER_FAILED = 'FETCH_FUTURE_REMINDER_FAILED';
export const FETCH_FUTURE_REMINDER = 'FETCH_FUTURE_REMINDER';
export const UPDATE_REMINDER_STATUS = 'UPDATE_REMINDER_STATUS';
export const UPDATE_REMINDER_STATUS_DONE = 'UPDATE_REMINDER_STATUS_DONE';
export const UPDATE_REMINDER_STATUS_FAILED = 'UPDATE_REMINDER_STATUS_FAILED';
export const UPDATE_REMINDER_DATE = 'UPDATE_REMINDER_DATE';
export const UPDATE_REMINDER_DATE_DONE = 'UPDATE_REMINDER_DATE_DONE';
export const UPDATE_REMINDER_DATE_FAILED = 'UPDATE_REMINDER_DATE_FAILED';
export const FETCH_USER_REMINDERS_FAILED = 'FETCH_USER_REMINDERS_FAILED';
export const FETCH_USER_REMINDERS_DONE = 'FETCH_USER_REMINDERS_DONE';
export const FETCH_USER_REMINDERS = 'FETCH_USER_REMINDERS';
