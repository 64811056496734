import * as types from '../types';

export const initialState = {
  closed:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDE_MENU :
      localStorage.setItem('closed', !state.closed);
      return {closed:!state.closed};
    case types.LOAD_MENU_SETTINGS :
      return {...state,closed:JSON.parse(action.menu)};
    default :
      return state
  }
}