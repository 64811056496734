import React from 'react';
import Icon from "../Icon/Icon";
import PropTypes from 'prop-types';
import style from './index.module.scss';

const ErrorBroken = ({error}) => {
  if(error){
    return (
      <div className={style.error}>
        <Icon icon='broken' className={style.icon}/>
        <p className={style.message}>{error}</p>
      </div>
    )
  }else return null
};

ErrorBroken.propTypes ={
  error:PropTypes.any,
};
export default ErrorBroken;