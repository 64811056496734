import React from 'react';
import Table from "../../../../components/ui/Table/Table";
import style from './index.module.scss';
import Icon from "../../../../components/ui/Icon/Icon";
import TextArea from "../../../../components/ui/TextArea/TextArea";
import {connect} from 'react-redux';
import * as actions from '../../../../store/dashboard/reporForm/reportForm.actions';
import {showReminder} from '../../../../store/reminders/reminders.actions';
import Button from "../../../../components/ui/Button/Button";
import Heading1 from "../../../../components/ui/Heading1/Heading1";
import ButtonBack from "../../../../components/ui/ButtonBack/ButtonBack";
import FileUploads from "../../../../components/FileUploads/FileUploads";
import ProductSelect from "../../../../components/ProductSelect/ProductSelect";
import InputPrimary from "../../../../components/ui/InputPrimary/InputPrimary";
import SelectedProducts from "../../../../components/SelectedProducts/SelectedProducts";
import {withRouter} from 'react-router-dom';
import Weekday from "../../../../components/Weekday/Weekday";

class ReportDetails extends React.Component {
  render() {
    const {props} = this;
    const {report, newReminder} = props;

    let icons = {
      phone: <Icon icon='phone' className={style.type}/>,
      meeting: <Icon icon='deal' className={style.type}/>,
      offer: <Icon icon='file' className={style.type}/>,
      sale: <Icon icon='clipboard' className={style.type}/>,
      other: <Icon icon='images' className={style.type}/>,
    };

    const saveNewReport = () => {
      if (!newReminder.data && (report.type === 'meeting' || report.type === 'offer')) {
        return props.showReminder(true);
      }
      props.saveReport(props.report, props.history)
    }

    return (
      <div className={style.details}>
        <Heading1>
          {icons[report.type]}
          {report.company.name}
        </Heading1>
        <ButtonBack onClick={() => props.goBack('type')}
                    className={style.back}/>
        <Table>
          <TextArea name='comment'
                    value={report.comment}
                    className={style.textarea}
                    error={report.errors.comment}
                    label='Komentaras (tikslas ir rezultatas)'
                    onChange={props.onInputChange}/>
          <div className={style.fields}>
            <ProductSelect report={props.report}
                           addProduct={props.addProduct}/>

            <Weekday report={report} onDaySelect={props.onDaySelect}/>

            {(report.type === 'sale' || report.type === 'offer') &&
              <>
                <SelectedProducts
                  report={report}
                  removeProduct={props.removeProduct}
                />
                <InputPrimary
                  name='price'
                  label='Bendra projekto vertė Eur'
                  type='number'
                  error={report.errors.price}
                  className={style.input}
                  onChange={props.onInputChange}
                />
                <FileUploads
                  report={props.report}
                  removeFile={props.removeFile}
                  uploadFile={props.addFile}
                />
              </>
            }
          </div>
          <div className={style.progress}>
            <div className={style.bar} style={{width: report.progress}}/>
          </div>
          {newReminder.data && <div className={style.reminderComment}>{newReminder.data.comment}</div>}
          <div className={style.buttons}>
            <Button color='white'
                    type='primary'
                    className={style.save}
                    onClick={saveNewReport}
                    loading={report.loading}>
              Išsaugoti
            </Button>
            {report.type !== 'comment' && <Button
              type='primary'
              color='white'
              onClick={() => props.showReminder(true)}
              className={style.reminder}
            >
              Sukurti priminimą
            </Button>}
          </div>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    report: state.dashboard.reportForm,
    newReminder: state.reminders.newReminder,
    auth: state.auth
  }
};
export default withRouter(connect(mapStateToProps, {...actions, showReminder})(ReportDetails));
