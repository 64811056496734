import React from 'react';
import style from './index.module.scss';
import logo from '../../assets/img/bobcat-logo-black.png';
import {connect} from 'react-redux';
import * as actions from '../../store/auth/login/login.actions';
import Button from "../../components/ui/Button/Button";
import Table from "../../components/ui/Table/Table";
import {Redirect} from 'react-router-dom';
import t from '../../utils/translations';

const Login = (props) => {
  if(props.user._id) return <Redirect to='/'/>;
  return (
    <Table className={style.login}>
      <div className={style.logo}>
        <img src={logo} alt=""/>
      </div>
      <form onSubmit={(e) => props.onFormSubmit(e, props.login, props.history)}>
        <div className={style.wrapper}>
          <label htmlFor="email">Paštas</label>
          <input autoComplete="new-email"
                 onChange={props.onInputChange}
                 type="text"
                 value={props.login.email}
                 id='email'/>
        </div>
        <div className={style.wrapper}>
          <label htmlFor="password">
            {props.login.errors.password ?
              <span>{props.login.errors.password}</span> :
              'Slaptažodis'
            }
          </label>
          <input autoComplete="new-password"
                 onChange={props.onInputChange}
                 type="password"
                 value={props.login.password}
                 id='password'/>
        </div>
        <Button color='white' radius={15}
                loading={props.login.loading}
                type='primary'
                className={style.btn}>
          {t.auth_login[props.lang]}
        </Button>
      </form>
    </Table>
  );
};

const mapStateToProps = state => {
  return {
    login: state.auth.login,
    user: state.auth.user,
    lang: state.settings.lang
  }
};


export default connect(mapStateToProps, actions)(Login);


