import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../Icon/Icon";
import style from "./index.module.scss";

const Stars = props => {
  let stars = [];
  for (let i = 1; i <= 5; i++) {
    const event = {target: {name: 'stars', value: i}};
    if (i <= props.starsCount) {
      stars.push(<Icon icon='star-solid'
                       key={i}
                       onClick={() => props.onInputChange(event)}
                       className={style.star_solid}/>)
    } else {
      stars.push(<Icon icon='star-regular'
                       key={i}
                       onClick={() => props.onInputChange(event)}
                       className={style.star_regular}/>)
    }
  }
  return (
    <div className={[style.stars, props.className].join(' ')}>
      <h3>Kliento įvertinimas</h3>
      {stars}
    </div>
  );
};

Stars.propTypes = {
  onInputChange:PropTypes.func,
  starsCount:PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Stars;