import * as types from '../types';
import axios from "axios";
import moment from "moment";
import {showError} from "../utils";

export const showReminder = (isVisible) => {
  return {
    type: types.SHOW_REMINDER,
    payload: isVisible,
  }
}

export const addReminder = (data) => {
  return {
    type: types.ADD_REMINDER,
    payload: data,
  }
}

export const saveReminder = (values) => {
  return async (dispatch, getState) => {
    const {newReminder} = getState().reminders
    if (newReminder.isSaving) return;
    try {
      const {data} = await axios.post('/api/reminders', values)
      dispatch({
        type: types.SAVE_REMINDER_DONE,
        payload: data,
      })
    } catch (e) {
      dispatch({
        type: types.SAVE_REMINDER_FAILED,
      })
      showError(dispatch, e)
    }
  }
}

export const getReminders = () => {
  return async (dispatch) => {
    try {
      const {data} = await axios.get('/api/reminders/past/' + moment().toISOString());
      dispatch({
        type: types.FETCH_REMINDERS_DONE,
        payload: data
      })
    } catch (e) {
      dispatch({
        type: types.FETCH_REMINDERS_FAILED
      })
      showError(dispatch, e)
    }
  }
}

export const getUserReminders = ({id, year, month}) => {
  return async (dispatch) => {
    try {
      const {data} = await axios.get(`/api/reminders/${id}/${year}/${month}`);
      dispatch({
        type: types.FETCH_USER_REMINDERS_DONE,
        payload: data
      })
    } catch (e) {
      dispatch({
        type: types.FETCH_USER_REMINDERS_FAILED
      })
      showError(dispatch, e)
    }
  }
}

export const getFutureReminders = () => {
  return async (dispatch) => {
    try {
      const {data} = await axios.get('/api/reminders/future/' + moment().toISOString());
      dispatch({
        type: types.FETCH_FUTURE_REMINDER_DONE,
        payload: data
      })
    } catch (e) {
      dispatch({
        type: types.FETCH_FUTURE_REMINDER_FAILED
      })
      showError(dispatch, e)
    }
  }
}

export const changeReminderStatus = ({id, hasSeen, isFuture}) => {
  return async (dispatch) => {
    try {
      await axios.put('/api/reminders/status', {id, hasSeen});
      dispatch({
        type: types.UPDATE_REMINDER_STATUS_DONE,
        payload: {id, hasSeen, isFuture}
      })
    } catch (e) {
      dispatch({
        type: types.UPDATE_REMINDER_STATUS_FAILED
      })
      showError(dispatch, e)
    }
  }
}

export const changeReminderDate = ({id, notifyAt, isFuture}) => {
  return async (dispatch) => {
    try {
      await axios.put('/api/reminders/notify-date', {id, notifyAt});
      dispatch({
        type: types.UPDATE_REMINDER_DATE_DONE,
        payload: {id, notifyAt, isFuture}
      })
    } catch (e) {
      dispatch({
        type: types.UPDATE_REMINDER_DATE_FAILED
      })
      showError(dispatch, e)
    }
  }
}

