import React, {useEffect, useState} from 'react';
import style from './index.module.scss';
import Table from "../../components/ui/Table/Table";
import PropTypes from 'prop-types';
import Icon from "../../components/ui/Icon/Icon";
import head from '../../assets/img/head-white.png';
import danger from '../../assets/img/danger.svg';
import {connect} from 'react-redux';
import * as actions from '../../store/dashboard/reporForm/reportForm.actions';
import {deleteReport} from '../../store/dashboard/weekly/weeklyReports.actions';
import ReportFiles from "./ReportFiles/ReportFiles";
import ReportStatus from "./ReportStatus/Status";
import ReportInfo from "./ReportInfo/ReportInfo";
import ReportButtons from "./ReportButtons/ReportButtons";
import ConfirmationModal from "../ui/ConfirmationModal/ConfirmationModal";

const ReportCard = (props) => {
  /** Add ESC key functionality */
  useEffect(() => {
    const hideReport = (e) => {
      if (props.report && e.keyCode === 27) props.showReport(false)
    };
    document.addEventListener('keydown', hideReport);
    return () => {
      document.removeEventListener('keydown', hideReport)
    }
    // eslint-disable-next-line
  }, []);

  const [deleteConfirmation, showConfirmation] = useState(false);

  const {report} = props;

  /** Add different label depending on the state of the offer */
  let activeLabelStyle;
  let activeLabel;
  if (report.type === 'offer' || report.type === 'sale') {
    if (report.state === 'sold') {
      activeLabelStyle = style.sold;
      activeLabel = <img src={head} className={style.head} alt=""/>
    }
    if (report.state === 'active') {
      activeLabelStyle = style.active;
      activeLabel = <img src={danger} className={style.danger} alt=""/>
    }
    if (report.state === 'canceled') {
      activeLabelStyle = style.canceled;
      activeLabel = <Icon icon='times' className={style.icon}/>
    }
  }

  /** Create adjustable styles */
  let cardType;
  if (report.type === 'offer' || report.type === 'sale') cardType = style.large;
  if (report.type === 'meeting' || report.type === 'phone') cardType = style.small;
  const classNames = [
    style.card,
    cardType,
    activeLabelStyle,
    props.className,
    props.position === 'absolute' ? style.absolute : ''
  ].join(' ');

  /** Create Icon depending on report type and status */
  let icon;
  if (report.type === 'phone') icon = <Icon icon='phone' className={style.icon}/>;
  if (report.type === 'meeting') icon = <Icon icon='deal' className={style.icon}/>;
  if (report.type === 'offer' || report.type === 'sale') {
    icon = activeLabel
  }

  return (
    <React.Fragment>
      <ConfirmationModal
        visible={deleteConfirmation}
        hideModal={()=>showConfirmation(false)}
        confirm={()=>props.deleteReport(report._id)}
        loading={false}
      >
        Ištrinti įrašą?
      </ConfirmationModal>
      {props.position === 'absolute' &&
      <div className={style.backdrop} onClick={() => props.showReport(false)}/>
      }
      <Table className={classNames}>
        {icon}
        <ReportInfo report={report} position='absolute'/>
        <ReportStatus report={report}/>
        <ReportFiles report={report}/>
        <ReportButtons showConfirmation={showConfirmation} report={report}/>
      </Table>
    </React.Fragment>
  );
};

ReportCard.propTypes = {
  report: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'static'])
};

export default connect(null, {...actions, deleteReport})(ReportCard);