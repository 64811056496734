import React  from 'react';
import {BrowserRouter, Switch,Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Roles from './routes/Roles/Roles';
import store from './store/index';
import jwt from 'jsonwebtoken';
import setToken from './utils/setToken';
import Login from "./routes/Login/Login";
import * as types from './store/types';

const token = localStorage.getItem('auth');
const menu = localStorage.getItem('closed');
if(menu){
  store.dispatch({
    type:types.LOAD_MENU_SETTINGS,
    menu
  })
}
if(token){
  const user = jwt.decode(token);
  setToken(token);
  store.dispatch({
    type:types.AUTH_USER,
    user:user.data
  })
}


const  App = (props) => (
  <div>
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <Route component={Roles}/>
        </Switch>
      </div>
    </BrowserRouter>
  </div>
);

const mapStateToProps = (state) =>{
    return {
        auth:state.auth
    }
};
export default connect(mapStateToProps)(App);
