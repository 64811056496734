import * as types from '../types';
import axios from 'axios';
import {showError} from '../utils';
import moment from 'moment';

export const fetchUsers = () => async dispatch => {
  try {
    const {data} = await axios.get('/api/users');
    dispatch({
      type: types.USERS_FETCHED,
      data
    })
  } catch (e) {
    showError(dispatch, e)
  }
};

export const onInputChange=e=> {
  const {name, value} = e.target;
  return {
    type: types.USER_FORM_INPUT_CHANGE,
    name,
    value
  }
};

export const createUser = (e, user, history) =>{
  e.preventDefault();
  console.log(user.day, user.day);
  const errors = {};
  if (!user.firstName) errors.firstName = 'Neįrašytas vardas';
  if (!user.lastName) errors.lastName = 'Neįrašyta pavardė';
  if (!user.email) errors.email = 'Neįrašytas paštas';
  if (!user.phone) errors.phone = 'Neįrašytas tel. nr.';
  if (!user.role) errors.role = 'Nepasirinktos pareigos';
  if (!user.status) errors.status = 'Nepasirinkta būsena';
  if (user.month>12) errors.birthday = 'Neteisingas mėnuo';
  if (user.month<1) errors.birthday = 'Neteisingas mėnuo';
  if (user.year>2010) errors.birthday = 'Neteisingi metai';
  if (user.year<1900) errors.birthday = 'Neteisingi metai';
  if (user.day>31) errors.birthday = 'Neteisinga diena';
  if (user.day<1) errors.birthday = 'Neteisinga diena';
  if (Object.keys(errors).length > 0) {
    return {
      type: types.USER_FORM_ERROR,
      errors
    }
  }
  const birthday = moment(`${user.year}-${user.month}-${user.day}`);
  return async function (dispatch) {

    try {
      dispatch({type: types.USER_FORM_LOADING});
      await axios.post('/api/users/update', {...user, birthday});

      history.push('/users/list');
      dispatch({type: types.USER_CREATED});
    } catch (e) {

      console.log(e);
      console.log(e.response);
      if (e.response.data === 'email already registered') {
        errors.email = 'Toks el. paštas užimtas';
        dispatch({
          type: types.USER_FORM_ERROR,
          errors
        })
      } else {
        showError(dispatch, e)
      }

    }
  }
}

export const loadUserForm = (user) =>{
  return {
    type: types.USER_LOAD_FORM,
    user
  }
};

export const clearUserForm = () =>{
  return {
    type: types.USER_CLEAR_FORM
  }
};

export const onDrop = (files, id)=>{
  const formData = new FormData();
  formData.append('userId', id);
  formData.append('image', files[0]);

  return async dispatch=>{
      try{
        const {data} = await axios.post('/api/users/image',formData);
        dispatch({
          type:types.USER_UPLOAD_IMAGE,
          image:data.image,
          id
        })
      }catch (e) {
        showError(dispatch, e)
      }
  };

};

export const resetPassword = ({email})=>{
    return async (dispatch)=>{
        try{
          await axios.post('/api/users/reset', {email})
          dispatch({
            type:types.USER_PASSWORD_RESET
          })
        }catch (e) {
          showError(dispatch, e)
        }
    }
};

export const onDeleteUser = ({_id, confirmCode}, history)=> async dispatch=>{
    try{
      await axios.post('/api/users/delete', {_id, confirmCode});
      dispatch({
        type:types.USER_DELETED
      });
      history.push('/users/list')
    }catch (e) {
      console.log(e.response)
      dispatch({
        type:types.USER_FORM_ERROR,
        errors:{confirmCode:'Neteisingas saugos kodas'}
      })
    }
}