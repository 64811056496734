import React from 'react';
import Table from "../../../../components/ui/Table/Table";
import style from './index.module.scss';
import Icon from "../../../../components/ui/Icon/Icon";
import {connect} from 'react-redux';
import * as actions from '../../../../store/dashboard/reporForm/reportForm.actions';
import Heading1 from "../../../../components/ui/Heading1/Heading1";
import ButtonBack from "../../../../components/ui/ButtonBack/ButtonBack";
import t from '../../../../utils/translations';

const TypeSelection = (props) => {
  const {lang} = props;
  return (
    <div  className={style.types}>
      <Heading1>{t.dashboard_select_type[lang]}</Heading1>
      <br/>
      <ButtonBack onClick={()=>props.goBack('companies')}
                  className={style.back}/>
      <div className={style.options}>
        <Table onClick={()=>props.selectType('phone')}
               className={style.type}>
          <Icon icon='phone' className={style.icon}/>
          {t.dashboard_type_phone[lang]}
        </Table>
        <Table onClick={()=>props.selectType('meeting')}
               className={style.type}>
          <Icon icon='deal' className={style.icon}/>
          {t.dashboard_type_meeting[lang]}
        </Table>
        <Table onClick={()=>props.selectType('offer')}
               className={style.type}>
          <Icon icon='file' className={style.icon}/>
          {t.dashboard_type_offer[lang]}
        </Table>
        <Table onClick={()=>props.selectType('comment')}
               className={style.type}>
          <Icon icon='comment' className={style.icon}/>
          {t.dashboard_type_comment[lang]}
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    lang:state.settings.lang
  }
};
export default connect(mapStateToProps, actions)(TypeSelection);
