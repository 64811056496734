import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/settings/password/passsword.actions';
import style from './index.module.scss';
import Button from "../../../components/ui/Button/Button";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Table from "../../../components/ui/Table/Table";
import Heading2 from "../../../components/ui/Heading2/Heading2";

const Password = (props) => {
  const {password} = props;

  const onPasswordSubmit = (e) => {
    e.preventDefault();
    const {oldPassword, newPassword, confirmPassword} = props.password;
    props.updatePassword(oldPassword, newPassword, confirmPassword)
  };

  return (
    <div className={style.password}>
      <Table className={style.table}>
        <form className={style.form} onSubmit={onPasswordSubmit}>
          <Heading2 className={style.heading}>
            Keisti slaptažodį
          </Heading2>

          <InputPrimary
            name='oldPassword'
            label='Senas slaptažodis'
            type='password'
            className={style.input}
            value={password.oldPassword}
            error={password.errors.oldPassword}
            onChange={props.onPasswordInputChange}/>
          <InputPrimary
            name='newPassword'
            label='Naujas slaptažodis'
            type='password'
            className={style.input}
            value={password.newPassword}
            error={password.errors.newPassword}
            onChange={props.onPasswordInputChange}/>
          <InputPrimary
            name='confirmPassword'
            label='Patvirtinti slaptažodį'
            type='password'
            className={style.input}
            error={password.errors.confirmPassword}
            value={password.confirmPassword}
            onChange={props.onPasswordInputChange}/>
          <Button
            color='white'
            type='primary'
            radius={15}
            className={style.btn}
            onClick={onPasswordSubmit}
            loading={password.loading}>
            {password.message ?
              'Slaptažodis pakeistas' :
              'Keisti'}
          </Button>
        </form>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    password: state.settings.password,
  }
};
export default connect(mapStateToProps, {...actions})(Password);

