import React from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../../store/dashboard/weekly/weeklyReports.actions';
import {selectCompany} from '../../../../store/dashboard/reporForm/reportForm.actions';
import Loader from "../../../../components/ui/Loader/Loader";
import moment from 'moment';
import Heading2 from "../../../../components/ui/Heading2/Heading2";
import {months} from '../../../../utils/dates';
import Icon from "../../../../components/ui/Icon/Icon";
import Button from "../../../../components/ui/Button/Button";
import {Link} from 'react-router-dom';

const types = {
  phone: <Icon icon='phone' className={style.type}/>,
  meeting: <Icon icon='deal' className={style.type}/>,
  offer: <Icon icon='file' className={style.type}/>,
  sale: <Icon icon='clipboard' className={style.type}/>,
  other: <Icon icon='images' className={style.type}/>,
  comment: <Icon icon='comment' className={style.type}/>,
};

const CompanyReports = props => {
  const {reports} = props.company;

  const createNewReport = () => {
    props.history.push('/dashboard/new-report');
    props.selectCompany(props.company)
  };

  const renderReportsHistory = () => {
    if (!reports) return <Loader loading/>;


    if (reports.length === 0) {
      return <div className='empty'>Įrašų nerasta</div>
    }

    // Create array with years from first report to last report date
    const firstYear = moment(reports[0].date).format('YYYY');
    const lastYear = moment(reports[reports.length - 1].date).format('YYYY');
    const yearsArray = [];

    for (let x = lastYear; x <= firstYear; x++) {
      yearsArray.unshift(parseInt(x))
    }

    return yearsArray.map((year) => {
      return (
        <div key={year} className={style.container}>
          <h4 className={style.year}>
            {year}
          </h4>
          {
            reports.filter((report) => {
              return parseInt(moment(report.date).format('YYYY')) === year
            })
              .map((report, i) => {
                const month = months[moment(report.date).format('MM') - 1];
                const day = moment(report.date).format('D');

                // Find if company belongs to same user and report to other
                let otherUser;
                if (report.user._id !== props.user._id && props.company.user._id === props.user._id) otherUser = true;

                return (
                  <div className={style.report} key={i}>
                    <div className={style.date}>
                      {month} {day}
                    </div>
                    <h3 className={[style.user, otherUser ? style.otherUser : ''].join(' ')}>
                      {report.user.firstName} {report.user.lastName}
                    </h3>

                    {types[report.type]}

                    <div className={style.comment}>
                      <p>{report.comment}</p>
                      {report.files.map((file, k) => {
                        return (
                          <a key={k} href={'/files/' + file} className={style.file} download>
                            {file}
                          </a>
                        )
                      })}
                      <h3 className={style.price}>
                        {report.price && report.price + '€'}
                      </h3>
                    </div>
                  </div>
                )
              })
          }
        </div>
      )
    });
  };

  return (
    <div className={style.history}>
      <Heading2 className={style.heading}>
        Įmonės įrašų istorija
      </Heading2>
      <Button className={style.btn}
              onClick={createNewReport}
              type='primary'>
        Naujas įrašas
      </Button>
      {renderReportsHistory()}
    </div>
  )
};

const mapStateToProps = state => {
  return {
    company: state.companies.companyForm,
    user: state.auth.user
  }
};


export default connect(mapStateToProps, {...actions, selectCompany})(CompanyReports);