import React, {useEffect} from 'react';
import {Route} from 'react-router-dom';
import Settings from "../../Settings/Settings";
import SideMenu from "../../../layout/SideMenu/SideMenu";
import menuData from './menuData';
import SampleComponents from "../../SampleComponents/SampleComponents";
import Categories from "../../Companies/Categories/Categories";
import Regions from "../../Companies/Regions/Regions";
import UsersList from "../../Users/UsersList/UsersList";
import CreateUser from "../../Users/CreateUser/CreateUser";
import SingleUser from "../../Users/SingleUser/SingleUser";
import UpdateUser from "../../Users/UpdateUser/UpdateUser";
import CompaniesList from "../../Companies/CompaniesList/CompaniesList";
import SingleCompany from "../../Companies/SingleCompany/SingleCompany";
import CreateCompany from "../../Companies/CreateCompany/CreateCompany";
import UpdateCompany from "../../Companies/UpdateCompany/UpdateCompany";
import NewRecord from "../../Dashboard/NewReport/NewReport";
import WeeklyReports from "../../Dashboard/WeeklyReports";
import Offers from "../../Dashboard/Offers";
import {connect, useDispatch} from 'react-redux';
import {fetchNotifications} from '../../../store/notifications/user/userNotifications.actions';
import Notifications from "../../Notifications/Notifications";
import Rent from "../../Rent";
import {Statistics} from '../../Statistics';
import {NewReminder} from "../../Dashboard/NewReminder";
import {Reminders} from "../../Reminders";
import {getReminders} from "../../../store/reminders/reminders.actions";
import {SelectedUserReminders} from "../../SelectedUserReminders";

const Admin = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    props.fetchNotifications();
    dispatch(getReminders());
  }, []);

  return (
    <React.Fragment>
      <NewReminder/>
      <SideMenu menu={menuData}/>
      <Route exact path='/reminders' component={Reminders}/>
      <Route exact path='/dashboard/user-reminders' component={SelectedUserReminders}/>
      <Route exact path='/' component={WeeklyReports}/>
      <Route path='/settings' component={Settings}/>
      <Route path='/components' component={SampleComponents}/>
      <Route path='/categories' component={Categories}/>
      <Route path='/regions' component={Regions}/>
      <Route path='/users' component={UsersList}/>
      <Route path='/create-user' component={CreateUser}/>
      <Route exact path='/user/:email/update' component={UpdateUser}/>
      <Route exact path='/user/:email' component={SingleUser}/>
      <Route exact path='/companies/list' component={CompaniesList}/>
      <Route path='/company/:id' component={SingleCompany}/>
      <Route exact path='/companies/create' component={CreateCompany}/>
      <Route exact path='/update-company/:id' component={UpdateCompany}/>
      <Route exact path='/dashboard/new-report' component={NewRecord}/>
      <Route exact path='/dashboard/reports/week' component={WeeklyReports}/>
      <Route exact path='/dashboard/offers' component={Offers}/>
      <Route exact path='/notifications' component={Notifications}/>
      <Route exact path='/rent' component={Rent}/>
      <Route exact path='/dashboard/statistics' component={Statistics}/>
    </React.Fragment>
  );
};

export default connect(null, {fetchNotifications})(Admin);
