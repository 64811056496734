import React, {useState, useEffect} from 'react';
import Table from "../../../components/ui/Table/Table";
import style from './index.module.scss';
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Button from "../../../components/ui/Button/Button";
import Icon from "../../../components/ui/Icon/Icon";
import {connect} from 'react-redux';
import * as actions from '../../../store/companies/categories/categories.actions';
import Loader from "../../../components/ui/Loader/Loader";
import Heading1 from "../../../components/ui/Heading1/Heading1";

const Categories = props => {

  const [name, setName] = useState('');
  const [errors, setErrors] = useState('');

  const {categories} = props;

  useEffect(() => {
    props.fetchCategories()
    // eslint-disable-next-line
  }, []);

  const addRegion = e => {

    e.preventDefault();

    if (!name) return;

    const match = categories.filter((item) => item.name === name.toLowerCase());
    if (match.length) return setErrors('Tokia kategorija jau sąraše');
    props.addCategory(name);
    setName('');
    setErrors('')

  };


  const renderList = () => {

    if (!categories) return <Loader loading={true}/>;

    return categories.map((item, i) => {
      return (
        <div key={item} className={style.categoryName}>
          <span>{i+1}.</span> <p>{item}</p>
          <Icon icon='trash'
                onClick={() => props.deleteCategory(item)}
                className={style.trash}/>
        </div>
      )
    });
  };


  return (
    <div className={style.categories}>
      <Heading1>Veiklos sritys</Heading1>
      <Table className={style.table}>
        <form onSubmit={addRegion}>
          <InputPrimary name='name'
                        label='Pavadinimas'
                        type='text'
                        value={name}
                        className={style.input}
                        error={errors}
                        onChange={(e) => setName(e.target.value)}
                        autoComplete={false}/>
          <Button className={style.button}
                  type='primary'>
            Įtraukti
          </Button>
        </form>
        {renderList()}
      </Table>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    categories: state.companies.categories
  }
};

export default connect(mapStateToProps, actions)(Categories);