import React, {useState} from 'react';
import Heading1 from "../../components/ui/Heading1/Heading1";
import Table from "../../components/ui/Table/Table";
import Button from "../../components/ui/Button/Button";
import style from './index.module.scss';
import Heading2 from "../../components/ui/Heading2/Heading2";
import InputPrimary from "../../components/ui/InputPrimary/InputPrimary";
import TextArea from "../../components/ui/TextArea/TextArea";
import Select from "../../components/ui/Select/Select";
import ConfirmationModal from "../../components/ui/ConfirmationModal/ConfirmationModal";
import Modal from "../../components/ui/Modal/Modal";
import Icon from "../../components/ui/Icon/Icon";
import Loader from "../../components/ui/Loader/Loader";
import ErrorBroken from "../../components/ui/ErrorBroken/ErrorBroken";
import TextEditor from "../../components/ui/TextEditor/TextEditor";

const SampleComponents = (props) => {
  const [confModal, toggleConfModal] = useState(false);
  const [modal, toggleModal] = useState(false);
  const [editorValue, setEditorValue] = useState("");

  return (
    <div>
      <Heading1>
        Components
      </Heading1>
      <Table className={style.table}>
        Table
        <br/>
        <Heading1>
          Heading1
        </Heading1>
        <Heading2 className={style.heading}>
          Heading2
        </Heading2>
      </Table>
      <Table className={style.table}>
        <Heading2 className={style.heading}>Buttons</Heading2>
        <Button type='primary' loading={true} className={style.btn}>
          Button loading
        </Button>
        <Button type='secondary' loading={true} className={style.btn}>
          Button loading
        </Button>
        <Button type='warning' loading={true} className={style.btn}>
          Button loading
        </Button>
        <br/>
        <Button type='primary' loading={false} className={style.btn}>
          primary
        </Button>
        <Button type='secondary' loading={false} className={style.btn}>
          secondary
        </Button>
        <Button type='warning' loading={false} className={style.btn}>
          warning
        </Button>
        <Button type='primary-outline' loading={false} className={style.btn}>
          primary outline
        </Button>
      </Table>
      <Table className={style.table}>
        <Heading2 className={style.heading}>Form</Heading2>
        <InputPrimary name='title'
                      label='Input component'
                      type='text'
                      onChange={(e) => console.log(e)}
                      value=''
                      className={style.input}
                      autoComplete={false}
                      lowercase/>
        <InputPrimary name='title'
                      label='Input with error'
                      type='text'
                      onChange={(e) => console.log(e)}
                      value=''
                      className={style.input}
                      error='Title too short'
                      autoComplete={false}
                      capitalize/>
        <TextArea name='area'
                  label='Text area'
                  onChange={(e) => console.log(e)}
                  className={style.textarea}
                  value=''/>
        <Select name='select'
                onChange={(e) => console.log(e)}
                className={style.select}
                options={['option 1', 'option 2', 'option 3']}
                value='' placeholder='select option'
                label='Select label '/>
        <Loader loading={true} className={style.loader}/>
        <ErrorBroken error='unexpected server error'/>
      </Table>

      <Table className={style.table}>
        <Heading2 className={style.heading}>Modals</Heading2>
        <Button type='primary'
                onClick={() => toggleConfModal(true)}
                className={style.btn}>
          Confirmation modal
        </Button>
        <ConfirmationModal visible={confModal}
                           hideModal={() => toggleConfModal(false)}
                           confirm={() => console.log('confirmed')}
                           loading={false}>
          Question
        </ConfirmationModal>

        <Button type='primary'
                onClick={() => toggleModal(true)}
                className={style.btn}>
          Simple modal
        </Button>
        <Modal visible={modal}
               hideModal={() => toggleModal(false)}>
          Simple modal
        </Modal>
      </Table>
      <Table className={style.icons}>
        <Heading2 className={style.heading}>Icons</Heading2>
        <div className={style.icon_container}>
          <p>chevron-left</p>
          <Icon icon='chevron-left' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>chevron-right</p>
          <Icon icon='chevron-right' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>chevron-down</p>
          <Icon icon='chevron-down' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>chevron-up</p>
          <Icon icon='chevron-up' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>bell</p>
          <Icon icon='bell' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>chart</p>
          <Icon icon='chart' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>clipboard</p>
          <Icon icon='clipboard' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>calendar</p>
          <Icon icon='calendar' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>deal</p>
          <Icon icon='deal' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>edit</p>
          <Icon icon='edit' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>file</p>
          <Icon icon='file' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>images</p>
          <Icon icon='images' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>phone</p>
          <Icon icon='phone' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>companies</p>
          <Icon icon='companies' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>dashboard</p>
          <Icon icon='dashboard' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>thumbtack</p>
          <Icon icon='thumbtack' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>pie</p>
          <Icon icon='pie' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>settings</p>
          <Icon icon='settings' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>flag-lt</p>
          <Icon icon='flag-lt' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>tasks</p>
          <Icon icon='tasks' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>users</p>
          <Icon icon='users' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>flag-us</p>
          <Icon icon='flag-us' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>bars</p>
          <Icon icon='bars' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>times</p>
          <Icon icon='times' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>check</p>
          <Icon icon='check' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>broken</p>
          <Icon icon='broken' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>logout</p>
          <Icon icon='logout' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>star-regular</p>
          <Icon icon='star-regular' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>star-solid</p>
          <Icon icon='star-solid' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>trash</p>
          <Icon icon='trash' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>world</p>
          <Icon icon='world' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>castle</p>
          <Icon icon='castle' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>news</p>
          <Icon icon='news' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>film</p>
          <Icon icon='film' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>save</p>
          <Icon icon='save' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>sign</p>
          <Icon icon='sign' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>success</p>
          <Icon icon='success' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>running</p>
          <Icon icon='running' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>running</p>
          <Icon icon='exclamation' className={style.icon}/>
        </div>
        <div className={style.icon_container}>
          <p>alarm</p>
          <Icon icon='alarm' className={style.icon}/>
        </div>
      </Table>
      <Table className={style.table}>
        <TextEditor onInputChange={(e)=>setEditorValue(e.target.value)}
                    onDelete={()=>console.log('deleted')}
                    moveUp={()=>console.log('moved up')}
                    moveDown={()=>console.log('moved down')}
                    value={editorValue}/>
      </Table>
    </div>
  );
};

export default SampleComponents;