import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const InputPrimary = props => {
  const textStyle={};
  if(props.lowercase) textStyle.textTransform='lowercase';
  if(props.capitalize) textStyle.textTransform='capitalize';
  return (
    <div className={[style.field, props.className].join(' ')}>
      <input
        style={textStyle}
        autoComplete={props.autoComplete===false ? `new-${props.name}`:null}
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder}
        id={props.name}
        value={props.value}
        type={props.type}/>
      <label htmlFor={props.name}>
        {props.error?
          <span>{props.error}</span>
          : props.label}
      </label>
    </div>
  );
};

InputPrimary.propTypes = {
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name:PropTypes.string.isRequired,
  label:PropTypes.string.isRequired,
  type:PropTypes.string.isRequired,
  value:PropTypes.any,
  onChange:PropTypes.func.isRequired,
  autoComplete:PropTypes.bool,
  placeholder:PropTypes.string,
  error:PropTypes.string,
  capitalize:PropTypes.bool,
  lowercase:PropTypes.bool,
};

export default InputPrimary;
