import * as types from '../../types';

export const initialState = {
  isAuth: '',
  role: '',
  errors: '',
  firstName:'',
  lastName:'',
  email: '',
  image: '',
  _id: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_LOADING :
      return {...state, loading: true};
    case types.AUTH_ERROR :
      return {...state,loading:false, errors:action.errors};
    case types.LOGIN_FORM_SUCCESS:
      return {...action.user, loading: false, errors: {},isAuth: true};
    case types.AUTH_USER:
      return {...action.user, loading: false, errors: {},isAuth: true};
    case types.LOGOUT_USER :{
      return initialState
    }
    case types.UPDATE_PROFILE:{
      const {firstName, lastName, email, phone} = action.profile;
      return {...state,firstName, lastName, email,phone, image:action.image}
    }

    default :
      return state
  }
}