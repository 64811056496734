import React, {useState} from 'react';
import style from './index.module.scss';
import {connect, useSelector} from 'react-redux';
import Icon from "../../components/ui/Icon/Icon";
import * as actions from '../../store/auth/user/user.actions';
import {changeLang} from '../../store/settings/lang/lang.actions';
import {withRouter, Link} from 'react-router-dom';

const UserInfo = (props) => {
  const [langDropdown, showDropDown] = useState(false);
  const {reminders} = useSelector((state)=>state.reminders);
  const {auth, notifications, lang} = props;

  const changeLanguage = (lang) => {
    showDropDown(false);
    props.changeLang(lang);
  };
  const langList = (
    <ul className={style.langList}>
      <li onClick={() => changeLanguage('lt')}>LT</li>
      <li onClick={() => changeLanguage('en')}>EN</li>
    </ul>
  );
  return (
    <div className={style.user}>
      <Link to='/reminders' className={style.reminders}>
        <Icon icon='reminder' className={style.icon}/>
        <span className={style.text}>Priminimai</span>
        {reminders.data?.length>0 && <div className={style.circle}>{reminders.data.length}</div>}
      </Link>
      {notifications && notifications.length > 0 &&
        <Link to='/notifications' className={style.notification}>
          {notifications.length}
        </Link>
      }
      <span>{auth.user.firstName} {auth.user.lastName}</span>
      {auth.user.image &&
        <div style={{backgroundImage: `url('/images/${auth.user.image}')`}}
             className={style.img}/>
      }
      <div className={style.lang}>
        <div onClick={() => showDropDown(true)}>
          {lang}
        </div>
        {langDropdown && langList}
      </div>
      <Icon onClick={() => props.logoutUser(props.history)}
            icon='logout'
            className={style.logout}/>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    auth: state.auth,
    lang: state.settings.lang,
    notifications: state.notifications.userNotifications
  }
};
export default withRouter(connect(mapStateToProps, {...actions, changeLang})(UserInfo));
