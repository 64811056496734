import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import Button from "../Button/Button";

const ConfirmationModal = (props) => {
  if(!props.visible) return null;
  return (
    <React.Fragment>
      <div className={style.backdrop} onClick={props.hideModal}/>
      <div className={style.modal}>
        <h4>{props.children}</h4>
        <Button onClick={props.hideModal} type='primary' className={style.no}>
          Ne
        </Button>
        <Button loading={props.loading}
                          onClick={()=>{
                            props.confirm();
                            props.hideModal()
                          }}
                          type='warning' className={style.yes} >
          Taip
        </Button>
      </div>
    </React.Fragment>
  );
};

ConfirmationModal.propTypes ={
  visible:PropTypes.bool.isRequired,
  children:PropTypes.node.isRequired,
  hideModal:PropTypes.func.isRequired,
  confirm:PropTypes.func.isRequired,
  loading:PropTypes.bool.isRequired
};

export default ConfirmationModal;