import * as types from '../../types';

export const initialState = {
  photo: '',
  firstName: '',
  lastName: '',
  email:'',
  phone:'',
  edit: false,
  errors: {},
  image:'',
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_EDIT_PROFILE : {
      if (action.value === false) return initialState;
      const {phone,email, firstName, lastName} = action.auth;
      return {...state, edit: action.value, phone,email, firstName, lastName};
    }
    case types.PROFILE_IMAGE_CHANGE:
      return {...state, image:action.image};
    case types.PROFILE_ERROR :
      return {...state, errors: action.errors};
    case types.PROFILE_INPUT_CHANGE:
      return {...state, [action.field]: action.value};
    case types.UPDATE_PROFILE :
      return initialState;
    case types.PROFILE_LOADING :
      return {...state, loading: true};
    default :
      return state
  }
}