import * as types from '../types';

const initialState = {
  reminders: {
    data: undefined,
    isLoading: false,
    error: ''
  },
  futureReminders: {
    data: undefined,
    isLoading: false,
    error: undefined,
  },
  newReminder: {
    isSaving: false,
    error: undefined,
    data: undefined,
    isVisible: false,
  },
  selectedUser:{
    isLoading: false,
    data: undefined,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_REMINDER :
      return {
        ...state,
        reminders: {...state.reminders},
        newReminder: {...state.newReminder, isVisible: action.payload}
      };
    case types.SAVE_REMINDER :
      return {
        ...state,
        newReminder: {isVisible: true, isSaving: true}
      }
    case types.SAVE_REMINDER_DONE :
      return {
        ...state,
        newReminder: {isVisible: false}
      }
    case types.SAVE_REMINDER_FAILED :
      return {
        ...state,
        newReminder: {isSaving: false, error: true}
      }
    case types.FETCH_USER_REMINDERS : {
      return {
        ...state, selectedUser: {isLoading: true}
      }
    }
    case types.FETCH_USER_REMINDERS_DONE: {
      return {
        ...state, selectedUser: {data: action.payload}
      }
    }
    case types.FETCH_USER_REMINDERS_FAILED: {
      return {
        ...state, selectedUser: {error: true}
      }
    }
    case types.FETCH_REMINDERS : {
      return {
        ...state, reminders: {isLoading: true}
      }
    }
    case types.FETCH_REMINDERS_DONE: {
      return {
        ...state, reminders: {data: action.payload}
      }
    }
    case types.FETCH_REMINDERS_FAILED: {
      return {
        ...state, reminders: {error: true}
      }
    }
    case types.FETCH_FUTURE_REMINDER : {
      return {
        ...state, futureReminders: {isLoading: true}
      }
    }
    case types.FETCH_FUTURE_REMINDER_DONE: {
      return {
        ...state, futureReminders: {data: action.payload}
      }
    }
    case types.FETCH_FUTURE_REMINDER_FAILED: {
      return {
        ...state, futureReminders: {error: true}
      }
    }
    case types.ADD_REMINDER: {
      return {
        ...state, newReminder: {...state.newReminder, data: action.payload, isVisible: false}
      }
    }
    case types.UPDATE_REMINDER_STATUS_DONE : {
      const {isFuture, id} = action.payload;
      if (isFuture) {
        const futureReminders = state.futureReminders.data.filter((r) => r._id !== id)
        return {
          ...state,
          futureReminders: {data: futureReminders}
        }
      } else {
        const reminders = state.reminders.data.filter((r) => r._id !== id)
        return {
          ...state,
          reminders: {data: reminders}
        }
      }
    }
    case types.UPDATE_REMINDER_DATE_DONE : {
      const {isFuture, notifyAt, id} = action.payload;
      if (isFuture) {
        const futureReminders = state.futureReminders.data.map((r) => (r._id === id ? {...r, notifyAt} : r))
        return {
          ...state,
          futureReminders: {data: futureReminders}
        }
      } else {
        const reminders = state.reminders.data.map((r) => (r._id === id ? {...r, notifyAt} : r))
        return {
          ...state,
          reminders: {data: reminders}
        }
      }
    }
    default :
      return state
  }
}
