import React from 'react';
import CompanySelection from './CompanySelection/CompanySelection';
import {connect} from 'react-redux';
import TypeSelection from './TypeSelection/TypeSelection';
import ReportDetails from "./ReportDetails/ReportDetails";

const NewReport = (props) => {
  if(props.report.type) return <ReportDetails/>;
  if(props.report.company) return <TypeSelection/>;
  return <CompanySelection/>;
};

const mapStateToProps = state => {
  return {
    report:state.dashboard.reportForm
  }
};
export default connect(mapStateToProps)(NewReport);