import * as types from './types';

/**
 * Show error
 * @param {function} dispatch
 * @param {[string, object]} err
 */
export function showError(dispatch, err) {
  let data;
  if(typeof err==='object' && err.hasOwnProperty('response')){
    console.log(err.response);
    data= JSON.stringify(err.response.data)
    if(err.response.data==='expiredJWT'){
      dispatch({type:types.LOGOUT_USER})
    }
  }else if(typeof err==='object'){
    console.log(err);
    data=JSON.stringify(err)
  }else{
    console.log(err);
    data=err
  }
  dispatch({
    type:types.SHOW_UNEXPECTED_ERROR,
    data
  })
}

