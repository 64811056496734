import React, {useState, useEffect, useRef} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import MenuItem from "./MenuItem/MenuItem";
import Icon from '../../components/ui/Icon/Icon';
import logo from '../../assets/img/bobcat-logo-white.png';
import * as actions from '../../store/layout/sideMenu.actions';

const SideMenu = props => {


  const [menu, changeMenu] = useState(props.menu);

  const node = useRef();

  const hideMenuItem = (e) => {

    // if menu is minimized and you click on it
    if (e.target.dataset.menu && props.sideMenu.closed) {
      changeMenu(menu.map((item) => ({...item, height: 0})))
    }
    // if you click outside menu
    if (!node.current.contains(e.target)) {
      // inside click
      changeMenu(menu.map((item) => ({...item, height: 0})))
    }

  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("click", hideMenuItem);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", hideMenuItem);
    };
  });


  const toggleSublinks = (id) => {
    const changedMenu = menu.map((item) => {
      if (item.id === id) {
        return {...item, height: item.height === 0 ? 'auto' : 0}
      } else {
        return {...item, height: 0}
      }
    });
    changeMenu(changedMenu)
  };

  const menuItems = menu.map((item, i) => {
    return <MenuItem toggleSublinks={toggleSublinks} item={item} key={i}/>
  });
  return (
    <div data-menu="true"
         ref={node}
         className={props.sideMenu.closed ? [style.menu, style.closed].join(' ') : style.menu}>
      <div className={style.logo}>
        <img src={logo} alt=""/>
      </div>
      {menuItems}
      <div className={style.slide} onClick={props.toggleMenu}>
        <Icon icon='chevron-left' className={style.slide_icon}/>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    lang: state.lang,
    sideMenu: state.sideMenu
  }
};

// SideMenu.protoTypes= {
//   menu:PropTypes.array.isRequired
// }

export default connect(mapStateToProps, actions)(SideMenu);