import React from 'react';
import {connect} from 'react-redux';
// Components
import Heading1 from "../../components/ui/Heading1/Heading1";
import Table from "../../components/ui/Table/Table";
import RentTable from './RentTable';
import Icon from "../../components/ui/Icon/Icon";
import InputPrimary from "../../components/ui/InputPrimary/InputPrimary";
import Menu, {MenuItem} from "../../components/ui/Menu";
import NewItem from "./NewItem";
// Store
import * as actions from '../../store/rent/rent.actions';
// Style
import style from './index.module.scss';
import StatusCard from "./StatusCard";

const Rent = (props) => {
  const {toggleMenu, onInputChange} = props;
  const {menuIsVisible} = props.rent;

  return (
    <div>
      <Heading1>Nuoma</Heading1>
      <Table>
        <div className={style.menu}>
          <Menu close={toggleMenu} show={menuIsVisible}>
            <MenuItem label='Įtraukti naują' onClick={props.toggleNewItemCard}/>
            <MenuItem label='Paslepti išnuomotą' onClick={() => console.log(99)}/>
            <MenuItem label='Atnaujinti' onClick={() => console.log(99)}/>
          </Menu>
          <Icon
            icon='bars'
            size='lg'
            className={style.icon}
            onClick={props.toggleMenu}
          />
          <InputPrimary
            name='search'
            label=' '
            placeholder='Paieška'
            type='text'
            onChange={onInputChange}
          />
        </div>
        <RentTable/>
        <NewItem/>
        <StatusCard/>
      </Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    rent: state.rent
  }
};
export default connect(mapStateToProps, actions)(Rent);
