import {combineReducers} from 'redux';
import passwordReducer from './password/password.reducer';
import profileReducer from './profile/profile.reducer';
import langReducer from './lang/lang.reducer';

export default combineReducers({
  password:passwordReducer,
  profile:profileReducer,
  lang:langReducer
})
