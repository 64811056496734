import React, {useEffect, useState} from 'react';
import Heading1 from "../../components/ui/Heading1/Heading1";
import {useDispatch, useSelector} from "react-redux";
import {getUserReminders} from "../../store/reminders/reminders.actions";
import Loader from "../../components/ui/Loader/Loader";
import {Row} from "./Row";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import Select from "../../components/ui/Select/Select";
import {fetchUsers} from "../../store/users/users.actions";
import Table from "../../components/ui/Table/Table";

const cx = classNames.bind(styles);

export const SelectedUserReminders = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const years = Array.from({length: 10}, (_, index) => ({value: 2023 + index, label: 2023 + index}));
  const months = Array.from({length: 12}, (_, index) => ({value: index + 1, label: index + 1}));

  const dispatch = useDispatch()
  const [user, selectUser] = useState();
  const [year, selectYear] = useState(currentYear);
  const [month, selectMonth] = useState(currentMonth);
  const {selectedUser} = useSelector((state) => state.reminders);
  const {usersList} = useSelector((state) => state.users);

  useEffect(() => {
    if (!user) return;
    dispatch(getUserReminders({id:user._id, year, month}))
  }, [user, year, month])

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const mapUsers = () => {
    if (!usersList) return []
    return usersList.map(user => {
      const {_id, firstName, lastName} = user;
      return {label: `${firstName} ${lastName}`, value: {_id, firstName, lastName}}
    });
  }

  const renderReminders = () => {
    if (!selectedUser.data && user) return <Loader loading={true}/>
    if (!user && !selectedUser.data) return null;
    return selectedUser.data.map((reminder) => <Row reminder={reminder} key={reminder._id}/>)
  }

  return (
    <div className={cx('container')}>
      <Heading1>Priminimai</Heading1>
      <Table className={cx('selection')}>
        <Select
          name='user'
          value={user}
          placeholder='Pasirinkti darbuotoją'
          className={cx('select-user')}
          onChange={(e) => selectUser(e.target.value)}
          options={mapUsers()}
          capitalize
          type='primary-light'
        />
        <Select
          name='year'
          value={year}
          placeholder='Metai'
          className={cx('select-year')}
          onChange={(e) => selectYear(e.target.value)}
          options={years}
          capitalize
          type='primary-light'
        />
        <Select
          name='month'
          value={month}
          placeholder='Menuo'
          className={cx('select-month')}
          onChange={(e) => selectMonth(e.target.value)}
          options={months}
          capitalize
          type='primary-light'
        />
      </Table>
      {renderReminders()}
    </div>
  );
};
