import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import * as actions from "../../../../store/users/users.actions";
import Loader from "../../../../components/ui/Loader/Loader";
import Table from "../../../../components/ui/Table/Table";
import style from "./index.module.scss";
import {Link} from "react-router-dom";
import {userRolesOptions} from "../../../../utils/user";

const UsersSearchList = props => {
  useEffect(() => {
    props.fetchUsers();
    // eslint-disable-next-line
  }, []);

  if (!props.users) return <Loader loading={true}/>;

  return props.users.map((user, i) => (
    <Table className={style.user} key={i}>
      {user.image ?
        <img className={style.image} src={`/images/${user.image}`} alt=""/> :
        <div className={style.image}/>
      }
      {props.onClick ?
      <div  className={style.name} onClick={()=>props.onClick(user)}>
        {user.firstName} {user.lastName}
      </div>:
        <Link  to={'/user/' + user.email} className={style.name}>
          {user.firstName} {user.lastName}
        </Link>
      }
      <p>{user.role}</p>
      <p>{userRolesOptions[user.role]}</p>
      <p className={style.contact}>{user.phone}</p>
      <p className={style.contact}>{user.email}</p>
    </Table>
  ));
};

UsersSearchList.propTypes = {
  onClick: PropTypes.func
};

const mapStateToProps = state => {
  return {
    users: state.users.usersList,
  }
};
export default connect(mapStateToProps, actions)(UsersSearchList);