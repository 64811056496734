import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const Table = props => {
  return (
    <div onClick={props.onClick} className={`${style.table} ${props.className}`}>
      {props.children}
    </div>
  );
};

Table.propTypes = {
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children:PropTypes.node.isRequired,
  onClick:PropTypes.func
};

export default Table;