import React, {useEffect, useState} from 'react';
import Modal from "../../../components/ui/Modal/Modal";
import style from './index.module.scss';
import {Calendar} from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../style/calendar-styles.scss';
import {useDispatch, useSelector} from "react-redux";
import TextArea from "../../../components/ui/TextArea/TextArea";
import Button from "../../../components/ui/Button/Button";
import {addReminder, showReminder} from "../../../store/reminders/reminders.actions";
import moment from "moment";

export const NewReminder = () => {
  const [date, onDateChange] = useState(new Date());
  const [comment, setComment] = useState('');
  const {type, company} = useSelector((state) => state.dashboard.reportForm);
  const {newReminder} = useSelector((state) => state.reminders);
  const [error, setError] = useState();
  const [dateError, setDateError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setComment('')
  }, [newReminder.isVisible])

  const values = {
    type,
    comment,
    companyId: company._id,
    notifyAt: date,
  }

  const submit = () => {
    const isAfter = moment(date).isAfter(Date.now())
    if (!comment) {
      setError('Neirašytas komentaras')
    } else if (!isAfter) {
      setDateError('Pasirink teisingą datą')
    } else {
      setError(undefined);
      setDateError(undefined);
      dispatch(addReminder(values))
    }
  }

  return (
    <Modal
      visible={!!newReminder.isVisible}
      hideModal={() => dispatch(showReminder(false))}
      className={style.modal}
    >
      <h4>Sukurti priminimą</h4>
      <div>
        <Calendar locale='lt' onChange={onDateChange} value={date}/>
      </div>
      <div className={style.comment}>
        <TextArea error={error} name='comment' value={comment} label='Komentaras'
                  onChange={(e) => setComment(e.target.value)}/>
      </div>
      {dateError && <div className={style.dateError}>{dateError}</div>}
      <div className={style.buttons}>
        <Button className={style.button} type='primary' onClick={submit}>
          Išsaugoti
        </Button>
      </div>
    </Modal>
  );
};
