import React from 'react';
import {connect} from 'react-redux';
import style from './index.module.scss';
import Heading1 from "../../components/ui/Heading1/Heading1";
import Table from "../../components/ui/Table/Table";
import {Link} from 'react-router-dom';
import moment from 'moment';
import Icon from "../../components/ui/Icon/Icon";
import * as actions from '../../store/notifications/user/userNotifications.actions';
import Loader from "../../components/ui/Loader/Loader";

const Notifications = (props) => {
  const {notifications} = props;

  const renderList = () => {
    if(!notifications) return <Loader loading={true}/>;
    if (!notifications.length) {
      return <div className={style.notFound}>Svarbių pranėšimų nera</div>
    }
    return notifications.map((n,i) => {
      let msg ='';
      let url='info';
      if (n.type === 'comment') url='history';
      if (n.type === 'comment') msg=' paliko įrašą įmonėje ';
      if (n.type === 'assignment') url='info';
      if (n.type === 'assignment') msg=' priskyrė įmonę ';
        return (
        <div className={style.notification} key={i}>
          <Icon icon='times'
                onClick={()=>props.deleteNotification(n._id)}
                className={style.btnTrash}/>
          <small>
            {moment(n.createdAt).format('YYYY-MM-DD')}
          </small>
          <strong>
            {`${n.creator.firstName} ${n.creator.lastName} `}
          </strong>
          {msg}
          <Link to={`/company/${n.company._id}/${url}`}>
            {n.company.name}
          </Link>
        </div>
      )
    })
  };

  return (
    <div className={style.notifications}>
      <Heading1>Pranešimai</Heading1>
      <Table className={style.table}>
        {renderList()}
      </Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    notifications: state.notifications.userNotifications
  }
};
export default connect(mapStateToProps, actions)(Notifications);