import React from 'react';
import PropTypes from 'prop-types';
import Select from "../ui/Select/Select";
import style from "./index.module.scss";
import {productTypes} from "../../utils/report";

const ProductSelect = props => {
  const {report} = props;

  if (report.type !== 'sale' && report.type !== 'offer') return null;

  const products = productTypes.filter((product) => {
    return !report.products.includes(product)
  });

  return (
    <Select name='product'
            label='Prekė'
            value={report.product}
            error={report.errors.product}
            className={`${style.select} ${props.className}`}
            onChange={props.addProduct}
            options={products}/>
  );
};

ProductSelect.propTypes = {
  report: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired,
  className:PropTypes.string,
};

export default ProductSelect;