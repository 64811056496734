import axios from 'axios';
import * as types from '../../types';
import {showError} from '../../utils';

export const fetchRegions = () => {
  return async function (dispatch) {
    try {
      let {data} = await axios.get('/api/regions');
      dispatch({
        type: types.REGIONS_FETCHED,
        data
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const deleteRegion = (name) => {
  return async function (dispatch) {
    try {
      await axios.delete('/api/regions/' + name);
      dispatch({
        type: types.REGION_DELETED,
        name
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const addRegion = (region) => {
  return async function (dispatch) {
    try {
      await axios.post('/api/regions', {name:region});
      dispatch({
        type: types.REGION_CREATED,
        region
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};