import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

const Button = (props) => {
  const {loading, onClick} = props;

  return (
    <button className={[props.className, style[props.type], style.button].join(' ')} onClick={onClick}>
      {typeof loading !== 'undefined' && loading ?
        <div className={style.spinner}/> :
        props.children
      }
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;