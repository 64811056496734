import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/users/users.actions';
import Heading1 from "../../../components/ui/Heading1/Heading1";
import UsersSearchList from "../_common/UsersSearchList/UsersSearchList";

const UsersList = () =>
  <div>
    <Heading1>Darbuotojų sąrašas</Heading1>
    <UsersSearchList/>
  </div>;

const mapStateToProps = state => {
  return {
    users: state.users.usersList,
  }
};
export default connect(mapStateToProps, actions)(UsersList);