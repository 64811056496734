export const companyTypes = [
  'UAB',
  'AB',
  'IĮ',
  'MB',
  'Ūkininkas',
  'Padalinys',
  'Valstybinė įmonė',
  'VšĮ',
  'Kita',
];

export const companiesWithCodes = [
  'UAB',
  'AB',
  'IĮ',
  'MB',
  'VšĮ',
];

export const statusList =[
  {label:'Aktyvi', value:'active'},
  {label:'Likviduota', value:'likviduota'},
  {label:'Nemoki', value:'nemoki'},
];