export default  {
  // Menu
  menu_dashboard:{en:'Dashboard', lt:'Suvestinė'},
  menu_new_record:{en:'New report', lt:'Naujas įrašas'},
  menu_week_record:{en:'Weekly reports', lt:'Savaitės įmonių įrašai'},
  menu_offers:{en:'Offers', lt:'Komerciniai pasiūlymai'},
  menu_companies:{en:'Companies', lt:'Įmonės'},
  menu_companies_list:{en:'Companies list', lt:'Įmonių sąrašas'},
  menu_company_create:{en:'Create company', lt:'Įtraukti naują'},
  menu_company_categories:{en:'Categories', lt:'Kategorijos'},
  menu_employees:{en:'Employees', lt:'Darbuotojai'},
  menu_notifications:{en:'Notifications', lt:'Pranešimai'},
  menu_settings:{en:'Settings', lt:'Nustatymai'},
  // Auth
  auth_login : {en:'Login', lt:'Prisijungti'},
  // Dashboard
  new_record : {en:'New record', lt:'Naujas įrašas'},
  phone_calls:{en:'Phone calls', lt:'Skambučiai'},
  meetings:{en:'Meetings', lt:'Susitikimai'},
  offers:{en:'Offers', lt:'Pasiūlymai'},
  comments:{en:'Comments', lt:'Komentarai'},
  weekly_report : {en:'weekly sales report', lt:'savaitinė darbų ataskaita'},
  dashboard_select_type:{en:'Select type', lt:'Pasirinkti tipą'},
  dashboard_type_phone :{en:'Phone call', lt:'Skambutis'},
  dashboard_type_meeting :{en:'Meeting', lt:'Susitikimas'},
  dashboard_type_offer :{en:'Offer', lt:'Pasiūlymas'},
  dashboard_type_comment :{en:'Comment', lt:'Komentaras'},
  // Companies
  companies_select:{en:'Select company', lt:'Pasirinkti įmonę'},
};
