import {combineReducers} from 'redux';
import categoriesReducer from './categories/categories.reducer';
import regionsReducer from './regions/regions.reducer';
import companyFormReducer from './form/companyForm.reducer';
import companiesListReducer from './list/companiesList.reducer';

export default combineReducers({
  regions:regionsReducer,
  categories:categoriesReducer,
  companyForm:companyFormReducer,
  companiesList:companiesListReducer
})