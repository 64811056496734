import * as types from '../../types';
import axios from 'axios';
import {showError} from '../../utils';

export const setSkip = ()=>{
    return {
      type:types.OFFERS_SET_SKIP
    }
}

export const fetchOffers = () => {
  return async (dispatch, getState) => {
    dispatch({type: types.OFFERS_LOADING});
    try {
      const {fields, limit, skip} = getState().dashboard.offers;
      const {data} = await axios.post('/api/offers', {...fields, limit, skip});
      dispatch({
        type: types.OFFERS_FETCHED,
        data,
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const onInputChange = (e) => {
  const {name, value} = e.target;
  return {
    type: types.OFFERS_INPUT_CHANGE,
    name, value
  }
};

export const onSearchInputChange = (e)=>{
    const {name, value} = e.target;
    return {
      type:types.OFFERS_SEARCH_INPUT_CHANGE,
      name,
      value
    }
};

export const clearFilter = ()=>{
  return {
    type:types.OFFERS_CLEAR_FILTER,

  }
}
