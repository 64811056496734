import React from 'react';
import Heading1 from "../../../components/ui/Heading1/Heading1";
import CompanySearchBar from "../../../components/CompanySearchBar";
import CompaniesSearchList from "../../../components/CompaniesSearchList/CompaniesSearchList";
import Button from "../../../components/ui/Button/Button";
import style from './index.module.scss';

const CompaniesList = props => {
  return (
    <div>
      <Heading1>Įmonių paieška</Heading1>
      <Button type='primary'
              onClick={()=>props.history.push('/companies/create')}
              className={style.btn}>
        Įtraukti naują
      </Button>
      <CompanySearchBar/>
      <CompaniesSearchList/>
    </div>
  );
};

export default CompaniesList;
