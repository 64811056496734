import React, {useState, useRef} from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';
import {useOutsideClick} from '../../../utils/useOutsideClick';
import Icon from "../Icon/Icon";

/** Option for Select */
export const Option = (props) => {
  return (
    <div
      className={style.item}
      onClick={() => props.onChange({target: {value: props.value, name: props.name}})}
    >
      {props.label ? props.label : props.value}
    </div>
  )
};
Option.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


/** Select */
const Select = (props) => {
  const [options, showOptions] = useState(false);
  const node = useRef(null);
  useOutsideClick(node, showOptions);

  const classNames = [
    style.select,
    props.error ? style.error : '',
    style[props.size],
    style[props.color],
    style[props.variant],
    props.className
  ].join(' ');

  let {size, color, variant, error, name, value} = props;

  // Clone childrens and append parent element's props
  let childrens = React.Children.toArray(props.children);
  let elements = childrens.map((children) => {
    return React.cloneElement(children, {
      onChange: props.onChange,
      color,
      size,
      variant,
      name
    })
  });


  props.children.forEach((child) => {
    if (child.props.label && JSON.stringify(child.props.value) === JSON.stringify(value)) {
      value=child.props.label;
    }
  });

  return (
    <fieldset
      ref={node}
      className={classNames}
      onClick={!props.disabled && !options ? () => showOptions(true) : null}
    >
      {options &&
      <div
        className={style.options}
        onClick={() => {
          showOptions(false)
        }}
      >
        {elements}
      </div>
      }
      <legend>{!value && error ? error : props.label}</legend>
      {!props.disabled &&
      <Icon
        icon='chevron-down' size='sm'
        color='grey'
        className={style.chevron}
      />
      }
      {value && <div className={style.value}>{value}</div>}
    </fieldset>
  );
};
Select.defaultProps = {
  size: 'md',
  variant: 'standard',
  color: 'primary'
};
Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['primary', 'secondary', 'grey']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled:PropTypes.bool
};
export default Select;