import * as types from '../../types';

const initialState = {
  list: null,
  loading: false,
  skip: 0,
  limit: 10,
  stopScroll: false,
  fields: {
    user: '',
    state: ''
  },
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REPORT_DELETED : {
      if (!state.list) return state;
      const list = state.list.filter((report) => {
        return report._id !== action.id
      });
      return {...state, list}
    }
    case types.REPORT_TOGGLE_EDIT : {
      if (!state.list) return state;
      const list = state.list.map((report) => {
        return report._id === action.reportId ?
          {...report, edit: !report.edit} :
          report
      });
      return {...state, list}
    }
    case types.REPORT_UPDATED : {
      if (!state.list) return state;
      const list = state.list.map((report) => {
        return report._id === action.report._id ? {...report, edit: false} : report
      });
      return {...state, list}
    }
    case types.OFFERS_SEARCH_INPUT_CHANGE :
      return {...state, fields: {...state.fields, [action.name]: action.value}, list: [], skip: 0, stopScroll: false};

    case types.REPORT_INPUT_CHANGE : {
      if (!state.list) return state;
      const list = state.list.map((report) => {
        return report._id === action.reportId ?
          {...report, [action.name]: action.value, edit: true} :
          report
      });
      return {...state, list}
    }
    case types.OFFERS_CLEAR_FILTER :
      return {...state, fields: initialState.fields};
    case types.REPORT_REMOVE_FILE : {
      if (!state.list) return state;
      const list = state.list.map((report) => {
        if (report.edit) {
          return {...report, files: report.files.filter(f => f !== action.file)}
        } else {
          return report
        }
      });
      return {...state, list}
    }
    case types.REPORT_FILE_UPLOAD : {
      if (!state.list) return state;
      const list = state.list.map((report) => {
        if (report.edit) {
          return {...report, files: [...report.files, ...action.files]}
        } else {
          return report
        }
      });
      return {...state, list}
    }
    case types.OFFERS_INPUT_CHANGE : {
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.name]: action.value
        },
        edit: true
      }
    }
    case types.OFFERS_LOADING :
      return {...state, loading: true};
    case types.OFFERS_FETCHED :
      return {
        ...state,
        list: state.list ? [...state.list, ...action.data] : action.data,
        loading: false,
        stopScroll: action.data.length < 10
      };
    case types.OFFERS_SET_SKIP :
      return {...state, skip: state.skip + 10, loading: true}
    default :
      return state
  }
}
