import React from 'react';
import PropTypes from 'prop-types';
import style from "./index.module.scss";

const TextArea = props => {
  return (
    <div className={[style.field, props.className].join(' ')}>
      {props.error ?
        <strong>{props.error}</strong> :
        <label htmlFor="notes">{props.label}</label>
      }
      <textarea value={props.value}
                onChange={props.onChange}
                name={props.name}/>
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextArea;