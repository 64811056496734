import * as types from '../../types';
import jwt from 'jsonwebtoken';
import axios from 'axios';

export function authUser(token) {
  const {data} = jwt.decode(token);
  return {
    type:types.AUTH_USER,
    user:data
  }
}

export function logoutUser() {
  localStorage.removeItem('auth');
  delete axios.defaults.headers.common['Authorization'];
  return {
    type:types.LOGOUT_USER
  }
}

