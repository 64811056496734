import * as types from '../../types';

export const initialState ={
  email:process.env.NODE_ENV==='production' ? '' :'admin',
  password:process.env.NODE_ENV==='production' ? '' :'admin',
  errors:{},
  loading:false
};
export default (state=initialState,action)=>{
    switch (action.type){
      case types.LOGIN_FORM_INPUT_CHANGE :
        return {...state, [action.field]:action.value};
      case types.LOGIN_FORM_LOADING :
        return {...state, loading:true, error:{}};
      case types.LOGIN_FORM_ERROR :
        return {...state, loading:false, errors: action.errors};
      case types.LOGIN_FORM_SUCCESS :
        return initialState;
      case types.LOGIN_FORM_CLEAR_ERROR:
        return {...state, errors:{}};
      default : return state
    }
}