import React, {useEffect} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../store/companies/form/companyForm.actions';
import {fetchCompanyReports, clearReports} from '../../../store/dashboard/weekly/weeklyReports.actions';
import Table from "../../../components/ui/Table/Table";
import {NavLink, Route} from 'react-router-dom';
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import Heading2 from "../../../components/ui/Heading2/Heading2";
import Heading1 from "../../../components/ui/Heading1/Heading1";
import ButtonBack from "../../../components/ui/ButtonBack/ButtonBack";
import CompanyReports from "./CompanyReports/CompanyReports";
// import CompanyTechnics from "./CompanyTechnics/CompanyTechnics";

const SingleCompany = props => {

  useEffect(() => {
    props.fetchCompany(props.match.params.id);
    props.fetchCompanyReports(props.match.params.id);
    return () => {
      props.clearCompanyForm();
      // props.clearReports()
    }
    // eslint-disable-next-line
  }, []);


  const {id} = props.match.params;
  const {company} = props;

  if (company.errors.notFound) return <Heading2>Įmonė nerasta</Heading2>;

  const to = props.location.search === '?dashboard' ? '/dashboard/reports/week' : '/companies/list';

  return (
    <div className={style.company}>
      <ButtonBack className={style.back}/>
      <div className={style.heading}>
        <Heading1>
          {company.image &&
          <img src={'/images/' + company.image} alt=""/>}
          {company.name}
          <span> {company.type}</span>
        </Heading1>
      </div>
      {company.user &&
      <Heading2 className={style.person}>
        {company.user.firstName} {company.user.lastName}
      </Heading2>
      }
      <Table className={style.table}>
        <NavLink to={`/company/${id}/info`}
                 className={style.info} activeClassName={style.active}>
          Bendra informacija
        </NavLink>
        <NavLink to={`/company/${id}/history`}
                 className={style.history} activeClassName={style.active}>
          Įmonės įrašų istorija
        </NavLink>
        <NavLink to={`/company/${id}/technics`}
                 className={style.technic} activeClassName={style.active}>
          Turima technika
        </NavLink>
        <Route path={`/company/:id/info`} component={CompanyInfo}/>
        <Route path={`/company/:id/history`} component={CompanyReports}/>
        {/*<Route path={`/company/:id/technics`} component={CompanyTechnics}/>*/}
      </Table>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    company: state.companies.companyForm
  }
};
export default connect(mapStateToProps, {
  ...actions,
  fetchCompanyReports,
  clearReports
})(SingleCompany);

