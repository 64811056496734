import * as types from '../types';

export const onInputChange = (e) => {
  const {name, value} = e.target;
  return {
    type: types.RENT_ON_CARD_INPUT_CHANGE,
    name,
    value
  }
}

export const toggleMenu = () => {
  return {
    type: types.RENT_TOGGLE_MENU,
  }
}

export const toggleNewItemCard = () => {
  return {
    type: types.RENT_TOGGLE_NEW_ITEM_CARD
  }
}

export const saveNewItem = (item) => {
  if(!item.name) {
    return {
      type:types.RENT_SAVE_ITEM_FAILED,
      errors:{name:'Neiįrašytas pavadinimas'}
    }
  }
  return async (dispatch) => {
    dispatch({
      type:types.RENT_SAVE_ITEM_STARTED
    })
    dispatch({
      type:types.RENT_SAVE_ITEM_DONE
    })
  }
}

export const openStatusCard = (card)=>{
    return {
      type:types.RENT_OPEN_STATUS_CARD,
      card
    }
}

export const updateItem = (user)=>{
    return {
      type:types.RENT_UPDATE_ITEM_DONE,
      user
    }
}
