import * as types from '../types';

const initialState = {
  card: {
    name: '',
    serialNr: '',
    location: '',
    state:'available',
    _id:Math.random(),
  },
  list: [
    {
      _id:Math.random(),
      name: 'Bobcat S250',
      serialNr: '98h234n09',
      location: 'vilnius',
      person: {
        firstName:'Romas',
        _id:123
      },
      state: 'available'
    },
    {
      _id:Math.random(),
      name: 'Bobcat S185',
      serialNr: '09iohhz12',
      location: 'vilnius',
      person: {
        firstName:'Valdas',
        _id:1234
      },
      state: 'available'
    }
  ],
  errors: {},
  menuIsVisible: false,
  cardIsVisible: false,
  statusIsVisible:false,
  isSaving: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.RENT_ON_CARD_INPUT_CHANGE :
      return {...state, card: {...state.card, [action.name]: action.value}}
    case types.RENT_TOGGLE_MENU :
      return {...state, menuIsVisible: !state.menuIsVisible};
    case types.RENT_SAVE_ITEM_STARTED :
      return {...state, isSaving: true}
    case types.RENT_SAVE_ITEM_DONE : {
      const list = [{...state.card, person: ''}, ...state.list];
      return {...state, cardIsVisible:false, isSaving: false, card: initialState.card, errors: {}, list}
    }
    case types.RENT_OPEN_STATUS_CARD : {
      return {...state, card: action.card, statusIsVisible: !state.statusIsVisible}
    }
    case types.RENT_UPDATE_ITEM_DONE : {
      const list = state.list.map((item)=>{
          return item._id===state.card._id ? {...state.card, person:action.user} : item
      })
      return {...state, list, card: initialState.card, statusIsVisible: false}
    }
    case types.RENT_TOGGLE_NEW_ITEM_CARD :
      return {...state, cardIsVisible: !state.cardIsVisible, menuIsVisible: false}
    default :
      return state
  }
}
