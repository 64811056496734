import * as types from '../../types';
import axios from 'axios';
import {showError} from '../../utils';

export const fetchCompanyReports = (id) => {
  return async function (dispatch) {
    dispatch({type: types.REPORTS_LOADING});
    try {
      const {data} = await axios.get('/api/reports/company/' + id);
      dispatch({
        type: types.REPORTS_FETCHED_COMPANY_REPORTS,
        data
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
}

export const deleteReport = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REPORT_DELETED,
        id
      });
      await axios.delete('/api/reports/single/' + id);
    } catch (e) {
      showError(dispatch, e)
    }
  }
};


export function fetchWeekReports(week, userId) {
  return async function (dispatch) {
    dispatch({type: types.REPORTS_LOADING});
    try {
      const {data} = await axios.get(`/api/reports/week/${week}/${userId}`);
      dispatch({
        type: types.REPORTS_LOAD_MORE,
        data,
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
}

export const fetchFirstWeeks = (userId) => {
  return async function (dispatch) {
    dispatch({type: types.REPORTS_LOADING});
    try {
      const res = await Promise.all([
        axios.get(`/api/reports/week/0/${userId}`),
        axios.get(`/api/reports/week/1/${userId}`),
        axios.get(`/api/reports/week/2/${userId}`),
      ]);
      dispatch({
        type: types.REPORTS_FETCHED_WEEKLY_REPORTS,
        data: [res[0].data, res[1].data, res[2].data],
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};


export const selectUser = (user) => {
  return {
    type: types.REPORTS_SELECT_USER,
    user
  }
};

export const clearReports = () => {
  return {
    type: types.REPORTS_CLEAR
  }
}

export const fetchComments = (userId, week) => {
  return async (dispatch) => {
    dispatch({type: types.COMMENTS_FETCH_STARTED});
    try {
      const {data} = await axios.get(`/api/comments/${userId}/${week}`);
      console.log(data)
      dispatch({
        type: types.COMMENTS_FETCH_DONE,
        data,
      })
    } catch (err) {
      showError(dispatch, err)
    }
  }
};

export const createComment = (userId, message) => {
  return async (dispatch) => {
    dispatch({type: types.COMMENTS_CREATE_STARTED})
    try {
      await axios.post('/api/comments', {message, userId});
    } catch (err) {
      showError(dispatch, err)
    }
  }
}

