import React, {useState} from 'react';
import {connect} from 'react-redux';
// Store
import * as actions from '../../../store/rent/rent.actions';
// Components
import Card from "../../../components/ui/Card";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Select, {Option} from "../../../components/ui/CustomSelect/Select";
// Style
import style from './index.module.scss';
import Button from "../../../components/ui/CustomButton/Button";

const NewItem = (props) => {
  const {toggleNewItemCard, onInputChange, saveNewItem} = props;
  const {cardIsVisible, card,errors,isSaving} = props.rent;
  return (
    <Card
      backdrop={true}
      closeModal={toggleNewItemCard}
      color='secondary'
      title='Įtraukti naują'
      visible={cardIsVisible}
      zIndex={30}
    >
      <InputPrimary
        name='name'
        label='Pavadinimas'
        onChange={onInputChange}
        type='text'
        error={errors.name}
        className={style.input}
      />
      <InputPrimary
        name='serialNr'
        label='Serijos nr'
        onChange={onInputChange}
        type='text'
        className={style.input}
      />
      <Select
        onChange={onInputChange}
        name='location'
        className={style.input}
        value={card.location}
        color='primary'
        label='Vieta'
        size='md'
        variant='standard'
      >
        <Option value='vilnius' label='Vilnius'/>
        <Option value='klaipeda' label='Klaipėda'/>
      </Select>
      <Button
        color='primary'
        onClick={()=>saveNewItem(card)}
        className={style.button}
        loading={isSaving}
      >
        Išsaugoti
      </Button>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    rent:state.rent
  }
};

export default connect(mapStateToProps, actions)(NewItem);
