import React from 'react';
import PropTypes from 'prop-types';
import style from "./index.module.scss";

const Weekday = props => {
  const {report} = props;
  const weekdayStrings = ['I', 'II', 'III', 'IV', 'V'];

  return (
    <div className={style.weekday}>
      {report.errors.weekday ?
        <small>{report.errors.weekday}</small> :
        <h4>Savaitės diena</h4>}
      <p>
                <span className={report.weekday === -1 ? style.active : null}
                      onClick={() => props.onDaySelect(-1)}>
                  {'V'}
                </span>
        {weekdayStrings.map((day, i) => (
          <span className={i === report.weekday ? style.active : null}
                onClick={() => props.onDaySelect(i)} key={i}>
                  {day}
                </span>
        ))}
      </p>
    </div>
  );
};

Weekday.propTypes = {
  report: PropTypes.object.isRequired,
  onDaySelect:PropTypes.func.isRequired
};

export default Weekday;