import React, {useEffect, useState, useRef} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import Select from "../ui/Select/Select";
import {fetchCategories} from '../../store/companies/categories/categories.actions';
import {fetchRegions} from '../../store/companies/regions/regions.actions';
import {fetchUsers} from '../../store/users/users.actions';
import * as actions from '../../store/companies/list/companiesList.actions';
import Icon from "../ui/Icon/Icon";
import AnimateHeight from 'react-animate-height';
import {companyTypes} from '../../utils/comapany';
import {hideOld} from "../../store/companies/list/companiesList.actions";

const stars = [
  {value: 1, label: '*'},
  {value: 2, label: '* *'},
  {value: 3, label: '* * *'},
  {value: 4, label: '* * * *'},
  {value: 5, label: '* * * * *'}
];

const dates = [
  {value: 3, label: 'Trys dienos'},
  {value: 7, label: 'Savaitė'},
  {value: 30, label: 'Mėnuo'},
  {value: 90, label: 'Trys mėnesiai'},
  {value: 182, label: 'Pusmetis'},
  {value: 365, label: 'Metai'},
];

const CompanySearchBar = props => {

  const {search} = props.companies;

  const [typing, setTyping] = useState(false);

  const refValue = useRef(search);

  useEffect(() => {
    refValue.current = search;
  });

  useEffect(() => {
    if (!props.regions || !props.categories) {
      props.fetchCategories();
      props.fetchRegions();
      props.fetchUsers()
    }
    if (!props.companies.list) props.fetchCompanies(search)
    // eslint-disable-next-line
  }, []);

  const onInputChange = e => {
    clearInterval(typing);
    props.onInputChange(e);
    setTyping(setTimeout(() => {
      props.fetchCompanies({...refValue.current})
    }, refValue.current.name ? 1500 : 0))
  };

  const clearFilter = () => {
    props.clearFilter();
    props.fetchCompanies({})
  }

  // users list for selection
  const users = props.users && props.users.map((user) => {
    const {_id, firstName, lastName} = user;
    return {value: {_id, firstName, lastName}, label: `${firstName} ${lastName}`}
  });
  if (props.users) users.push({value: {_id: null}, label: 'Nepriskirtos įmonės'});

  return (
    <div className={style.searchBar}>
      <div className={style.basic}>
        <Icon
          icon='times'
          onClick={clearFilter}
          className={style.clearFilterBtn}
        />
        <input
          className={[style.name, style.input].join(' ')}
          onChange={onInputChange}
          name='name'
          value={search.name}
          placeholder='Pavadnimas | kodas'/>
        <Select name='user'
                onChange={onInputChange}
                className={style.select}
                value={search.user}
                placeholder='Priskirtas asmuo'
                type='primary-light'
                capitalize
                options={users || []}/>
        <Select
          name='region'
          value={search.region}
          onChange={onInputChange}
          className={style.select}
          placeholder='Regionas'
          type='primary-light'
          capitalize
          options={props.regions || []}/>
        <Select
          name='category'
          onChange={onInputChange}
          className={style.select}
          value={search.category}
          placeholder='Kategorija'
          type='primary-light'
          capitalize
          options={props.categories || []}/>
        <div className={style.advancedButton}
             onClick={props.toggleAdvancedSearch}>
          <Icon icon='chevron-down' className={[style.icon, style.first].join(' ')}/>
          <Icon icon='chevron-down' className={[style.icon, style.second].join(' ')}/>
        </div>
      </div>
      <AnimateHeight duration={200}
                     className={style.expanded}
                     height={props.companies.expanded}>
        <div className={style.wrapper}>
          <Select name='stars'
                  onChange={onInputChange}
                  className={style.select}
                  value={search.stars}
                  placeholder='Kliento įvertinimas'
                  type='primary-light'
                  capitalize
                  options={stars}/>
          <Select name='type'
                  onChange={onInputChange}
                  className={style.select}
                  value={search.type}
                  placeholder='Įmonės tipas'
                  type='primary-light'
                  capitalize
                  options={companyTypes}/>
          <div className={style.hide}>
            <div className={style.text}>Paslėpti likviduotas</div>
            <div
              className={style.checkbox}
              onClick={props.hideOld}>
              {search.hiddenOld && 'x'}
            </div>
          </div>
        </div>
        <div className={style.wrapper}>
          <p>Nelankyti klientai po komercinio pasiūlymo ilgiau nei:</p>
          <Select name='notVisitedAfterOffer'
                  onChange={onInputChange}
                  className={style.date}
                  value={search.notVisitedAfterOffer}
                  type='primary-light'
                  capitalize
                  options={dates}/>
        </div>
        <div className={style.wrapper}>
          <p>Nelankyti klientai po pardavimo ilgiau nei:</p>
          <Select name='notVisitedAfterSale'
                  onChange={onInputChange}
                  className={style.date}
                  value={search.notVisitedAfterSale}
                  type='primary-light'
                  capitalize
                  options={dates}/>
        </div>
        <div className={style.wrapper}>
          <p>Nelankyti klientai ilgiau nei:</p>
          <Select name='notVisitedAfterAny'
                  onChange={onInputChange}
                  className={style.date}
                  value={search.notVisitedAfterAny}
                  type='primary-light'
                  capitalize
                  options={[...dates, {value: -1, label: 'Niekad nelankyti'}]}/>
        </div>
        {(props.user.role === 'admin' || props.user.role === 'manager') &&
        <div className={style.wrapper}>
          <div className={style.excell} onClick={props.saveToFile}>Išsaugoti į Excell</div>
        </div>
        }
      </AnimateHeight>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    regions: state.companies.regions,
    categories: state.companies.categories,
    companies: state.companies.companiesList,
    users: state.users.usersList
  }
};
export default connect(mapStateToProps, {
  fetchCategories,
  fetchRegions,
  fetchUsers,
  ...actions
})(CompanySearchBar);
