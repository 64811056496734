import * as types from '../../types';


export default (state=null,action)=>{
    switch (action.type){
      case types.NOTIFICATIONS_FETCH :
        return action.data;
      case types.NOTIFICATIONS_DELETED :
        return state.filter((n)=>n._id!==action.id);
      default : return state
    }
}