import React from 'react';
import Heading1 from "../../../../components/ui/Heading1/Heading1";
import style from './index.module.scss';
import CompanySearchBar from "../../../../components/CompanySearchBar";
import CompaniesSearchList from "../../../../components/CompaniesSearchList/CompaniesSearchList";
import {selectCompany} from '../../../../store/dashboard/reporForm/reportForm.actions';
import {connect} from 'react-redux';
import t from '../../../../utils/translations';

const CompanySelection = (props) => {
  return (
    <div className={style.companySelection}>
      <Heading1>{t.companies_select[props.lang]}</Heading1>
      <CompanySearchBar/>
      <CompaniesSearchList onClick={props.selectCompany}/>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    lang:state.settings.lang
  }
};
export default connect(mapStateToProps, {selectCompany})(CompanySelection);
