import * as types from "../../types";
import axios from "axios";
import {showError} from '../../utils';

export function onPasswordInputChange(e) {
  return {
    type: types.PASSWORD_INPUT_CHANGE,
    value: e.target.value,
    field: e.target.id
  }
}

export function updatePassword(oldPassword, newPassword, confirmPassword) {
  const errors = {};
  if (!oldPassword) errors.oldPassword = 'Neįrašytas senas slaptažodis';
  if (!newPassword) errors.newPassword = 'Neįrašytas naujas slaptažodis';
  if (newPassword !== confirmPassword) errors.confirmPassword = 'Slaptažodžiai nesutampa';
  if (Object.keys(errors).length > 0) {
    return {
      type: types.PASSWORD_FORM_ERROR,
      errors
    }
  }
  return async function (dispatch) {
    try {
      dispatch({
        type: types.PASSWORD_FORM_LOADING,
      });
      await axios.post('/api/settings/password', {oldPassword, newPassword});
      dispatch({
        type: types.PASSWORD_FORM_SUBMIT,
        message: 'password changed'
      });
      setTimeout(() => {
        dispatch({
          type: types.PASSWORD_FORM_SUBMIT,
          message: ''
        })
      }, 2000)
    } catch (e) {
      console.log(e.response);
      if (e.response.data === 'wrong password') {
        errors.oldPassword = 'neteisingas senas slaptažodis';
        dispatch({
          type: types.PASSWORD_FORM_ERROR,
          errors
        })
      } else {
        showError(dispatch, e)
      }
    }
  }
}
