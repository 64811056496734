import * as types from '../../types';
import axios from 'axios';

export const fetchStatistics = (user, from, to) => {
  return async (dispatch) => {
    dispatch({type: types.FETCH_STATISTICS_STARTED})
    try {
      const {data} = await axios.get(`/api/statistics?user=${user}&from=${from}&to=${to}`);
      console.log(data)
      dispatch({
        payload: data,
        type: types.FETCH_STATISTICS_DONE,
      })
    } catch (e) {

    }
  }
}

export const clearStats = () => {
  return {
    type: types.CLEAR_STATISTICS
  }
}
