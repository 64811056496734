import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Heading1 from "../../components/ui/Heading1/Heading1";
import {fetchUsers} from "../../store/users/users.actions";
import Select, {Option} from "../../components/ui/CustomSelect/Select";
import InputPrimary from "../../components/ui/InputPrimary/InputPrimary";
import style from './index.module.scss';
import Button from "../../components/ui/Button/Button";
import {clearStats, fetchStatistics} from "../../store/dashboard/statistics/statistics.actions";
import Loader from "../../components/ui/Loader/Loader";
import Icon from "../../components/ui/Icon/Icon";

export const Statistics = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const dispatch = useDispatch();
  const [values, setValues] = useState({id: '', from: `${currentYear}-01-01`, to: `${currentYear + 1}-01-01`});
  const {dashboard, users} = useSelector((state) => state);
  const {statistics} = dashboard;
  const {usersList} = users;

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const onInputChange = (e) => {
    const {name, value} = e.target;
    setValues({...values, [name]: value})
  }

  const renderUsers = () => {
    if (!usersList) return [];
    return usersList.map(({_id, firstName, lastName}) => {
      return <Option key={_id} value={_id} label={firstName + ' ' + lastName}/>
    })
  }

  const onSubmit = () => {
    const {user, from, to} = values;
    if (!user || !from || !to) return;
    dispatch(fetchStatistics(user, from, to))
  }

  const renderResults = () => {
    const {data} = statistics;
    return data.map((item) => {
      const {from, to, meetings, phoneCalls, totalContacts, numberOfCompaniesVisited, offers,} = item;
      return (
        <div className={style.stats}>
          <div className={style.name}>{item.user.firstName} {item.user.lastName}</div>
          <div className={style.date}>{from} - {to}</div>
          <div className={style.row}>
            <Icon className={style.icon} icon='phone'/> Skambučiai: {phoneCalls}
          </div>
          <div className={style.row}>
            <Icon className={style.icon} icon='deal'/> Susitikimai: {meetings}
          </div>
          <div className={style.row}>
            <Icon className={style.icon} icon='file'/> Pasiūlymai: {offers}
          </div>
          <div className={style.row}>
            Bendras kiekis: {totalContacts}
          </div>
          <div className={style.row}>
            Skirtingų klientų aplankyta:{numberOfCompaniesVisited}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <Heading1>Statistika</Heading1>
      <div className={style.searchbox}>
        <Select value={values.user} className={style.user} size='lg' variant='standard' label='Darbuotojas'
                onChange={onInputChange}
                name='user'>
          {renderUsers()}
        </Select>
        <InputPrimary value={values.from} className={style.from} name='from' label='Nuo' type='text'
                      onChange={onInputChange}
                      placeholder='2000-01-01'/>
        <InputPrimary value={values.to} className={style.to} name='to' label='Iki' type='text' onChange={onInputChange}
                      placeholder='2000-01-01'/>
        <Button onClick={onSubmit} type='primary' className={style.button}>Ieškoti</Button>
      </div>
      <div className={style.results}>
        {/*{statistics.isLoading && <Loader loading={true}/>}*/}
        {statistics.error && <div className={style.error}>Neteisingai įvesti duomenys</div>}
        {renderResults()}
      </div>
      {statistics.data.length > 0 && <Button type='secondary' onClick={()=>dispatch(clearStats())} className={style.button}>Išvalyti</Button>}
    </div>
  );
};
