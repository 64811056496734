import React, {useEffect} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../store/companies/list/companiesList.actions';
import Loader from "../ui/Loader/Loader";
import Table from "../ui/Table/Table";
import Icon from "../ui/Icon/Icon";
import {Link} from 'react-router-dom';
import Heading2 from "../ui/Heading2/Heading2";
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import moment from 'moment';

const CompaniesSearchList = props => {

  const {search} = props.companies;

  const scroll = debounce(() => {
    if (props.companies.stopScroll) return;
    const body = document.body,
      html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.scrollY + window.innerHeight * 1.2 > height && !props.companies.loading) {
      props.fetchCompanies({...search, page: search.page + 1}, search.page + 1);
    }
  }, 500);


  useEffect(() => {

    document.addEventListener('scroll', scroll);

    return () => {
      document.removeEventListener('scroll', scroll);
    }

    // eslint-disable-next-line
  }, [scroll]);

  const renderCompanies = () => {
    if (!props.companies.list) return;
    if (props.companies.list.length === 0) {
      return <Heading2 className={style.notFound}>Įmonių nerasta</Heading2>
    }
    return props.companies.list
      .filter((company) => {
        if (search.hiddenOld && company.status === 'likviduota') return false;
        return company;
      })
      .map((company, i) => {

        const renderStars = () => {
          const stars = [];
          for (let x = 1; x <= 5; x++) {
            stars.push(company.stars >= x ?
              <Icon key={x} className={style.star_solid} icon='star-solid'/> :
              <Icon key={x} className={style.star_regular} icon='star-regular'/>)
          }
          return stars
        };

        const categories = company.categories.map((category, i) => (
          <div key={i} className={style.category}>{category}, </div>
        ));

        return (
          <Table className={style.company} key={i}>
            <h2>
              {props.onClick ?
                <div onClick={() => props.onClick(company)}>
                  {company.name}
                  <span>{company.type}</span>
                  {company.status && company.status !== 'active' &&
                  <small className={style.status}>{company.status}</small>
                  }
                </div> :
                <Link to={`/company/${company._id}/info`}>
                  {company.name}
                  <span>{company.type}</span>
                  {company.status && company.status !== 'active' &&
                  <small className={style.status}>{company.status}</small>
                  }
                </Link>
              }
            </h2>
            <div className={style.categories}>
              {categories}
              {company.lastReport.date ?
                <div className={style.date}>
                  {moment(company.lastReport.date).format('YYYY-MM-DD')}
                </div> :
                <div className={style.notVisited}>
                  nelankyta įmonė
                </div>
              }
            </div>
            {company.image &&
            <div className={style.logo}>
              <img src={'/images/' + company.image} alt=""/>
            </div>
            }
            <p className={style.region}>{company.region}</p>
            <p className={style.user}>{company.user.lastName}</p>
            <div className={style.stars}>{renderStars()}</div>
          </Table>
        )
      })
  };

  return (
    <>
      {renderCompanies()}
      <Loader loading={props.companies.loading}/>
    </>
  );
};


const mapStateToProps = state => {
  return {
    companies: state.companies.companiesList,
    user: state.auth.user
  }
};
CompaniesSearchList.propTypes = {
  onClick: PropTypes.func
};

export default connect(mapStateToProps, actions)(CompaniesSearchList);
