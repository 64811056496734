import * as types from '../../types';

const initialState = {
  data: [],
  isLoading: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STATISTICS_STARTED :
      return {...state, isLoading: true, error: undefined}
    case types.FETCH_STATISTICS_DONE :
      return {...state, data: [...state.data, action.payload], isLoading: false};
    case types.FETCH_STATISTICS_FAILED :
      return {...state, error: true, isLoading: false}
    case types.CLEAR_STATISTICS :
      return {...state, data: []}
    default :
      return state
  }
}
