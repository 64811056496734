import React, {useEffect} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as companyActions from '../../../store/companies/form/companyForm.actions';
import {fetchRegions} from '../../../store/companies/regions/regions.actions';
import {fetchCategories} from '../../../store/companies/categories/categories.actions';
import {fetchUsers} from '../../../store/users/users.actions';
import Button from "../../../components/ui/Button/Button";
import Select from "../../../components/ui/Select/Select";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Table from "../../../components/ui/Table/Table";
import TextArea from "../../../components/ui/TextArea/TextArea";
import Dropzone from "react-dropzone";
import {companyTypes} from '../../../utils/comapany';
import Icon from "../../../components/ui/Icon/Icon";
import Heading1 from "../../../components/ui/Heading1/Heading1";
import Heading2 from "../../../components/ui/Heading2/Heading2";
import Stars from "../../../components/ui/Stars/Stars";
import {companiesWithCodes} from '../../../utils/comapany';
import FetchDataButton from "../../../components/FetchDataButton/FetchDataButton";

const CreateCompany = props => {

  useEffect(() => {
    props.fetchCategories();
    props.fetchRegions();
    if(props.user.role!=='admin'){
      props.assignDefaultUser(props.user);
    }
    // Add users to state if auth position 1 or 2
    props.fetchUsers();
    // eslint-disable-next-line
  }, []);


  // users list for selection
  const users = props.users && props.users.map((user) => {
    const {_id, firstName, lastName} = user;
    return {value: {_id, firstName, lastName}, label: `${firstName} ${lastName}`}
  });

  // categories list for selection
  const categories = props.company.categories.map((cat, i) => {
    return (
      <li onClick={() => props.removeCategory(cat)} key={i}>{cat}</li>
    )
  });

  const categoriesList = props.categories && props.categories.filter((item) => {
    return props.company.categories.indexOf(item) < 0;
  }).sort((a, b) => a > b ? 1 : -1);

  const contacts = props.company.contacts.map((contact, i) => {
    return (
      <div className={style.contact} key={i}>
        <Icon icon='trash'
              onClick={() => props.removeContact(i)}
              className={style.trash}/>
        <InputPrimary name='firstName'
                      label='Vardas'
                      type='text'
                      value={contact.firstName}
                      onChange={(e) => props.onContactInputChange(i, e)}/>
        <InputPrimary name='lastName'
                      label='Pavardė'
                      type='text'
                      value={contact.lastName}
                      onChange={(e) => props.onContactInputChange(i, e)}/>
        <InputPrimary name='position'
                      label='Pareigos'
                      type='text'
                      value={contact.position}
                      onChange={(e) => props.onContactInputChange(i, e)}/>
        <InputPrimary name='phone'
                      label='Telefonas'
                      type='text'
                      value={contact.phone}
                      onChange={(e) => props.onContactInputChange(i, e)}/>
        <InputPrimary name='email'
                      label='El. paštas'
                      type='text'
                      value={contact.email}
                      onChange={(e) => props.onContactInputChange(i, e)}/>
      </div>
    )
  });

  return (
    <div className={style.create}>
      <Heading1>Įtraukti Įmonę</Heading1>
      <Table className={style.table}>
        <Heading2 className={style.h2}>
          Įmonės duomenys
          <a href='https://rekvizitai.vz.lt/'
             rel="noopener noreferrer"
             target='_blank'>
            www.rekvizitai.lt
          </a>
        </Heading2>
        <Dropzone
          accept="image/*"
          onDrop={props.onDrop}>
          {({getRootProps, getInputProps}) => (
            <div className={style.file}{...getRootProps()}>
              <input {...getInputProps()} />
              {props.company.image ?
                <img src={props.company.image.preview} alt=""/> :
                <p className={style.file_text}>Įkelti nuotrauką</p>
              }
            </div>
          )}
        </Dropzone>
        <div className={style.form}>
          <InputPrimary name='name'
                        label='Pavadinimas'
                        type='text'
                        error={props.company.errors.name}
                        value={props.company.name}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          <FetchDataButton/>
          <InputPrimary name='address'
                        label='Adresas'
                        type='text'
                        error={props.company.errors.address}
                        value={props.company.address}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          {companiesWithCodes.includes(props.company.type) &&
          <InputPrimary name='code'
                        label='Įmonės kodas'
                        type='number'
                        error={props.company.errors.code}
                        value={props.company.code}
                        autoComplete={false}
                        className={style.input}
                        hide={props.company.type > 10}
                        onChange={props.onInputChange}/>
          }
          <InputPrimary name='link'
                        label='Rekvizitai.lt'
                        type='text'
                        error={props.company.errors.code}
                        value={props.company.link}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>

          <Select name='type'
                  className={style.select}
                  label='tipas'
                  value={props.company.type}
                  error={props.company.errors.type}
                  options={companyTypes}
                  onChange={props.onInputChange}/>

          <Select name='region'
                  className={style.select}
                  label='Regionas'
                  capitalize
                  value={props.company.region}
                  error={props.company.errors.region}
                  options={props.regions || []}
                  onChange={props.onInputChange}/>

          <Select name='user'
                  className={style.select}
                  label='Priskirtas asmuo'
                  value={props.company.user}
                  capitalize
                  error={props.company.errors.user}
                  options={users || []}
                  onChange={props.onInputChange}/>

          <Select name='categories'
                  className={style.select}
                  label='Veiklos sritys'
                  value={null}
                  error={props.company.errors.categories}
                  options={categoriesList || []}
                  onChange={props.addCategory}/>

          <Stars className={style.stars}
                 onInputChange={props.onInputChange}
                 starsCount={props.company.stars}/>

          <ul className={style.categories}>
            {categories}
          </ul>


          <div className={style.contacts}>
            <Heading2 className={style.h2}>
              Kontaktiniai asmenys
            </Heading2>
            {props.company.errors.contact &&
            <small>{props.company.errors.contact}</small>
            }
            <Button className={style.add}
                    onClick={props.addContact}
                    type='primary-outline'>
              Pridėti
            </Button>
            {contacts}
          </div>

          <Heading2 className={style.h2}>
            Kita
          </Heading2>
          <TextArea name='desc'
                    label='Aprašymas ir komentarai'
                    value={props.company.desc}
                    className={style.textarea}
                    onChange={props.onInputChange}/>

          <Button onClick={() => props.onFormSubmit(props.company, props.history)}
                  type='primary'
                  className={style.btn}>
            Sukurti
          </Button>
        </div>
      </Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    company: state.companies.companyForm,
    regions: state.companies.regions,
    categories: state.companies.categories,
    user: state.auth.user,
    users: state.users.usersList
  }
};
export default connect(mapStateToProps, {
  fetchCategories,
  fetchRegions,
  fetchUsers,
  ...companyActions,
})(CreateCompany);