import React from 'react';
import Password from "./Password/Password";
import style from './index.module.scss'
import Profile from "./Profile/Profile";
import Heading1 from "../../components/ui/Heading1/Heading1";

const Settings = () =>
  (
    <div className={style.account}>
      <div className={style.heading}>
        <Heading1>Nustatymai</Heading1>
      </div>
        <Profile/>
        <Password/>
    </div>
  );

export default Settings
