import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';


const Heading1 = props => {
  return (
    <h1 onClick={props.onClick}
      className={[style.heading1, props.className].join(' ')}>
      {props.children}
    </h1>
  );
};

Heading1.propTypes = {
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children:PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick:PropTypes.func
};

export default Heading1;