import React, {useEffect} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../store/users/users.actions';
import Table from "../../../components/ui/Table/Table";
import Loader from "../../../components/ui/Loader/Loader";
import Button from "../../../components/ui/Button/Button";
import Heading2 from "../../../components/ui/Heading2/Heading2";
import {userRolesOptions} from '../../../utils/user';

const SingleUser = props => {

  useEffect(() => {
    if (!props.users) props.fetchUsers();
    // eslint-disable-next-line
  }, []);

  if (!props.users) {
    return <Loader loading={true}/>
  }

  const [user] = props.users.filter((user) => user.email === props.match.params.email);

  if (!user) return (
    <div className={style.notFound}>
      <h2>
        Toks darbuotojas nerastas
      </h2>
    </div>
  )


  return (
    <div className={style.user}>
      <Table className={style.table}>
        <div className={style.photo}
             style={{backgroundImage: user.image ? `url('/images/${user.image}')` : null}}/>
        <Heading2>{user.firstName} {user.lastName}</Heading2>
        <small>{userRolesOptions[user.role]}</small>
        <div className={style.line}/>
        <p className={style.contacts}>{user.email}</p>
        <a className={style.contacts} href={'tel:' + user.phone}>{user.phone}</a>
        <p className={style.desc}>{user.desc}</p>
        <Button onClick={() => props.history.push(`/user/${user.email}/update`)}
                className={style.button}
                type='primary'>
          Redaguoti
        </Button>
      </Table>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    users: state.users.usersList
  }
};

export default connect(mapStateToProps, actions)(SingleUser);