import * as types from '../../types';

export default (state ='lt' , action) => {
  switch (action.type) {
    case types.CHANGE_LANG :
      return action.lang
    default :
      return state
  }
}
