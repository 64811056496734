import axios from 'axios';
import * as types from '../../types';
import {showError} from '../../utils';

export const fetchCategories = () => {
  return async function (dispatch) {
    try {
      let {data} = await axios.get('/api/categories');
      dispatch({
        type: types.CATEGORIES_FETCHED,
        data
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const deleteCategory = (category) => {
  return async function (dispatch) {
    try {
      await axios.delete('/api/categories/' + category);
      dispatch({
        type: types.CATEGORY_DELETED,
        category
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const addCategory = (category) => {
  return async function (dispatch) {
    try {
      await axios.post('/api/categories', {name: category});
      dispatch({
        type: types.CATEGORY_CREATED,
        category
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};
