import React from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../store/users/users.actions';
import Button from "../../../components/ui/Button/Button";
import Select from "../../../components/ui/Select/Select";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Table from "../../../components/ui/Table/Table";
import TextArea from "../../../components/ui/TextArea/TextArea";
import Heading1 from "../../../components/ui/Heading1/Heading1";
import {userRolesOptions, userStatusOptions} from '../../../utils/user';

const CreateUser = (props) => {

  return (
    <div className={style.create}>
      <Heading1>Įtraukti Darbuotoją</Heading1>
      <Table className={style.table}>

        <form onSubmit={(e) => props.createUser(e, props.user, props.history)}>
          <InputPrimary name='firstName'
                        label='Vardas'
                        type='text'
                        capitalize={true}
                        error={props.user.errors.firstName}
                        value={props.user.firstName}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          <InputPrimary name='lastName'
                        label='Pavarde'
                        type='text'
                        capitalize={true}
                        error={props.user.errors.lastName}
                        value={props.user.lastName}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          <InputPrimary name='email'
                        label='Paštas'
                        type='text'
                        lowercase
                        value={props.user.email}
                        error={props.user.errors.email}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          <InputPrimary name='phone'
                        label='Tel. nr.'
                        type='text'
                        value={props.user.phone}
                        error={props.user.errors.phone}
                        autoComplete={false}
                        className={style.input}
                        onChange={props.onInputChange}/>
          <div className={style.date}>
            {props.user.errors.birthday?
              <span>{props.user.errors.birthday}</span>:
              <h4>Gimimo data</h4>
            }
            <input type="text"
                   name='year'
                   value={props.user.year}
                   onChange={props.onInputChange}
                   placeholder='MMMM'/>
            <input type="text"
                   name='month'
                   value={props.user.month}
                   onChange={props.onInputChange}
                   placeholder='MM'/>
            <input type="text"
                   name='day'
                   value={props.user.day}
                   onChange={props.onInputChange}
                   placeholder='DD'/>
          </div>
          <Select name='role'
                  className={style.select}
                  label='Pareigos'
                  value={props.user.role}
                  error={props.user.errors.role}
                  options={userRolesOptions}
                  onChange={props.onInputChange}/>
          <Select name='status'
                  className={style.select}
                  label='Būsena'
                  value={props.user.status}
                  error={props.user.errors.status}
                  options={userStatusOptions}
                  onChange={props.onInputChange}/>
          <TextArea name='desc'
                    label='Aprašymas ir komentarai'
                    value={props.user.desc}
                    className={style.textarea}
                    onChange={props.onInputChange}/>
          <Button color='white'
                  type='primary'
                  className={style.btn}>
            Sukurti
          </Button>
        </form>
      </Table>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    user: state.users.userForm,
  }
};
export default connect(mapStateToProps, actions)(CreateUser);