import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/settings/profile/profile.actions';
import style from './index.module.scss'
import Dropzone from 'react-dropzone'
import joinClasses from '../../../utils/joinClasses'
import Table from "../../../components/ui/Table/Table";
import Button from "../../../components/ui/Button/Button";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Heading2 from "../../../components/ui/Heading2/Heading2";

const classes = joinClasses(style);

const Profile = (props) => {
  const onProfileSubmit = (e) => {
    e.preventDefault();
    props.updateProfile(props.profile)
  };
  const {profile, auth} = props;
  return (
    <div className={style.profile}>
      <Table className={style.table}>

        {profile.edit ?
          <Dropzone
            accept="image/*"
            onDrop={props.onDrop}>
            {({getRootProps, getInputProps}) => (
              <div style={{backgroundImage: `url('${profile.image.preview}')`}}
                   className={style.photo}
                   {...getRootProps()}>
                <input {...getInputProps()} />
                <p className={style.text}>Įkelti nuotrauką</p>
              </div>
            )}
          </Dropzone>
          :
          <div style={{backgroundImage: auth.user.image ? `url('/images/${auth.user.image}')` : null}}
               className={style.photo}/>
        }

        {profile.edit ?
          <form className={style.form} onSubmit={onProfileSubmit}>
            <InputPrimary
              name='firstName'
              label='Vardas'
              type='text'
              capitalize
              className={style.input}
              value={profile.firstName}
              error={profile.errors.firstName}
              autoComplete={false}
              onChange={props.onProfileInputChange}/>
            <InputPrimary
              name='lastName'
              label='Pavardė'
              type='text'
              capitalize
              error={profile.errors.lastName}
              className={style.input}
              value={profile.lastName}
              onChange={props.onProfileInputChange}/>
            <InputPrimary
              name='email'
              label='Paštas'
              type='text'
              lowercase
              className={style.input}
              error={profile.errors.email}
              value={profile.email}
              onChange={props.onProfileInputChange}/>
            <InputPrimary
              name='phone'
              label='Telefonas'
              type='text'
              error={profile.errors.phone}
              className={style.input}
              value={profile.phone}
              onChange={props.onProfileInputChange}/>

            <input type="submit"
                   className={classes('input hidden')}/>
          </form>
          :
          <div className={style.details}>

            <Heading2 className={style.name}>
              {auth.user.firstName} {auth.user.lastName}
            </Heading2>
            <div className={style.line}/>
            <a href={`tel:${auth.user.phone}`} className={style.contacts}>{auth.user.phone}</a>
            <a href={`mailto:${auth.user.email}`} className={style.contacts}>{auth.user.email}</a>
          </div>
        }

        <br/>
        <div className={style.controls}>
          {profile.edit ?
            <React.Fragment>
              <Button
                color='white'
                radius={14}
                type='primary'
                loading={profile.loading}
                onClick={() => props.updateProfile(profile)}
                className={style.btn}>
                Išsaugoti
              </Button>

              <Button onClick={() => props.toggleEditProfile(false)}
                      type='secondary'
                      className={style.btn}>
                Atšaukti
              </Button>
            </React.Fragment> :
            <Button
              type='primary'
              onClick={() => props.toggleEditProfile(true, auth.user)}
              className={style.btn}>
              Keisti
            </Button>
          }
        </div>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.settings.profile
  }
};
export default connect(mapStateToProps, actions)(Profile);