import {combineReducers} from 'redux';
import reportForm from './reporForm/reportForm.reducer';
import weeklyReports from './weekly/weeklyReports.reducer';
import offers from './offers/offers.reducer';
import statistics from './statistics/statistics.reducer';

export default combineReducers({
  reportForm,
  weeklyReports,
  offers,
  statistics,
})
