import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';
import Icon from "../Icon/Icon";

let offsetX = 0;
let offsetY = 0;

const Card = props => {
  const node = useRef(null);

  const {color, visible} = props;
  if (!visible) return null;
  const classNames = [
    style.modal,
    props.className,
    style[color]
  ].join(' ');


  const moveTable = function (e) {
    node.current.style.left = e.clientX - offsetX + 'px';
    node.current.style.top = e.clientY - offsetY + 'px';
    node.current.style.transform = 'translate(0 , 0)';
  };

  return (
    <React.Fragment>
      <div
        className={`${style.backdrop} ${style[props.backdrop]}`}
        style={{zIndex: props.zIndex - 1}}
        onClick={props.closeModal}
      />
      <div
        ref={node}
        className={classNames}
        style={{zIndex: props.zIndex}}
      >
        <header
          className={`${style.header}`}
          style={{cursor: props.draggable ? 'grab' : ''}}
          onMouseDown={(e) => {
            offsetX = e.clientX - node.current.getBoundingClientRect().x;
            offsetY = e.clientY - node.current.getBoundingClientRect().y;
            window.addEventListener('mousemove', moveTable, true);
          }}
          onMouseUp={() => {
            window.removeEventListener('mousemove', moveTable, true);
          }}
        >
          <h3 className={style.title}>
            {props.title}</h3>
          <Icon
            icon='times'
            onClick={props.closeModal}
            color='light'
            size='md'
          />
        </header>
        {props.children}
      </div>
    </React.Fragment>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  backdrop: PropTypes.oneOf(['dark', 'transparent']).isRequired,
  visible: PropTypes.bool.isRequired,
  zIndex: PropTypes.number.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Card;
