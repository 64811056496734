import * as types from '../../types';

const search = {
  page: 0,
  type: '',
  user: '',
  stars: '',
  notVisitedAfterOffer: '',
  notVisitedAfterSale: '',
  notVisitedAfterAny: '',
  name: '',
  region: '',
  category: '',
  hiddenOld: false,
};

const initialState = {
  loading: false,
  expanded: 0,
  errors: {},
  list: null,
  stopScroll: false,
  search
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_HIDE_OLD :{
      return {
        ...state, search: {...state.search, hiddenOld: !state.search.hiddenOld}
      }
    }
    case types.COMPANIES_INPUT_CHANGE :
      if (action.name.includes('notVisited')) {
        return {
          ...state,
          stopScroll: false,
          search: {
            ...state.search,
            page: 0,
            notVisitedAfterAny: '',
            notVisitedAfterOffer: '',
            notVisitedAfterSale: '',
            [action.name]: action.value
          }
        }
      }
      return {
        ...state,
        stopScroll: false,
        search: {...state.search, page: 0, [action.name]: action.value}
      };
    case types.USER_DELETED :
      return initialState;
    case types.AUTH_USER : {
      const {_id, firstName, lastName,role} = action.user;
      if (role === 'sales') {
        return {
          ...state, search: {
            ...state.search, user: {_id, firstName, lastName}
          }
        }
      }else return state;
    }
    case types.LOGOUT_USER :
      return initialState;
    case types.COMPANY_DELETED:
      return {...state, list: null};
    case types.COMPANY_UPLOAD_IMAGE :
      return {...state, list: null};
    case types.COMPANY_SAVED :
      return {...state, list: null};
    case types.COMPANIES_LOADING :
      return {...state, loading: true};
    case types.COMPANIES_TOGGLE_ADVANCED_SEARCH : {
      const expanded = state.expanded === 'auto' ? 0 : 'auto';
      return {...state, expanded};
    }
    case types.COMPANIES_CLEAR_FILTER :
      return {...state, search: {...initialState.search}};
    case types.COMPANIES_FETCHED :
      //
      if (action.page) {
        return {
          ...state,
          list: [...state.list, ...action.data],
          search: {...state.search, page: action.page},
          loading: false,
          stopScroll: action.data.length < 15 // disable scroll if we get no data
        };
      } else {
        return {
          ...state,
          stopScroll: action.data.length < 15,
          list: [...action.data,],
          loading: false
        };
      }
    default :
      return state
  }
}
