import * as types from '../../types';
import moment from 'moment';

const initialState = {
  company: '',
  type: '',
  _id: '',
  products: [],
  comment: '',
  weekday: moment().isoWeekday() - 1,
  date: moment(),
  files: [],
  resume: '',
  price: '',
  errors: {},
  progress: 0,
  state: '',
  reason:'',
  loading: false,
  edit: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REPORT_DELETED :
      return initialState;
    case types.REPORT_TOGGLE_EDIT :
      return {...state, edit: !state.edit};
    case types.REPORT_SHOW_REPORT :
      return {...initialState, ...action.report, weekday: moment(action.report.date).isoWeekday() - 1};
    case types.REPORT_FETCH_SINGLE :
      return {...initialState, ...action.data};
    case types.REPORT_FORM_CLEARED :
      return initialState;
    case types.REPORT_UPDATED:
      return initialState;
    case types.LOGOUT_USER :
      return initialState;
    case types.REPORT_SELECT_COMPANY :
      return {...initialState, company: action.company};
    case types.REPORT_SELECT_TYPE :
      return {...state, type: action.value, state: action.value === 'offer' ? 'active' : null};
    case types.REPORT_INPUT_CHANGE :
      return {...state, [action.name]: action.value, edit: true};
    case types.REPORT_ADD_PRODUCT :
      if (!action.product) return state;
      return {...state, products: [...state.products, action.product]};
    case types.REPORT_SAVED :
      return {...initialState};
    case types.REPORT_LOADING :
      return {...state, loading: true};
    case types.REPORT_ERROR :
      return {...state, loading: false, errors: action.errors};
    case types.REPORT_DAY_SELECT : {
      let day, date;
      if (action.day === -1) {
        day = moment().isoWeekday() + 2;
        date = moment().subtract(day, 'day');
      } else {
        day = moment().isoWeekday() - 1 - action.day;
        date = moment().subtract(day, 'day');
      }
      return {...state, date, weekday: action.day};
    }
    case types.REPORT_FILE_UPLOAD : {
      return {...state, files: [...state.files, ...action.files]}
    }
    case types.REPORT_REMOVE_PRODUCT : {
      const products = state.products.filter((p) => p !== action.product);
      return {...state, products}
    }
    case types.REPORT_REMOVE_FILE : {
      return {...state, files: state.files.filter(file => file !== action.file)}
    }
    case types.REPORT_UPLOAD_PROGRESS :
      return {...state, progress: action.value};
    case types.REPORT_GO_BACK : {
      if (action.where === 'companies') return initialState;
      if (action.where === 'type') return {...initialState, company: state.company}
      return state;
    }
    default :
      return state
  }
}