import React from 'react';
import style from './index.module.scss';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../store/dashboard/reporForm/reportForm.actions';
import PropTypes from 'prop-types';

const ReportInfo = (props) => {
  const report  = {...props.report, errors:props.report.errors || {}};
  const {company, user} = report;

  const commentHeight = (report.type === 'offer' || report.type === 'sale') ?
    report.comment.length / 70 * 25 + 'px' :
    report.comment.length / 40 * 25 + 'px';

  return (
    <div className={`${style.info} ${style.info}`}>
      <div className={style.date}>
        {moment(report.date).format('YYYY-MM-DD')}
      </div>
      <div className={style.wrapper}>
        {report.company &&
        <Link to={`/company/${company._id}/history`} className={style.company}>
          {company.name.length > 36 ? company.name.slice(0, 36) + '...' : company.name}
        </Link>
        }
        {report.price && !report.edit &&
        <span className={style.price}>({report.price + '€'})</span>
        }
        {report.price && report.edit &&
        <input
          className={style.priceInput}
          name='price'
          value={report.price}
          onChange={(e)=>props.onInputChange(e, report._id)}
        />
        }
        <h3 className={style.user}>
          {user.firstName} {user.lastName}
        </h3>
      </div>

      {report.edit ?
        <fieldset className={`${style.editComment} ${report.errors.comment ? style.error : ''}`}>
          {report.errors.comment &&
          <legend>{report.errors.comment}</legend>
          }
          <textarea
            style={{height: commentHeight}}
            name='comment'
            onChange={(e)=>props.onInputChange(e, report._id)}
            value={report.comment}
          />
        </fieldset> :
        <p className={style.comment}>
          {report.comment}
        </p>
      }
    </div>
  );
};

ReportInfo.propTypes = {
  report: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.oneOf(['absolute', 'static'])
};

export default connect(null, actions)(ReportInfo);
