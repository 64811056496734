import * as types from '../../types';
import axios from 'axios';
import {showError} from '../../utils';
import XLSX from "xlsx";

export const hideOld = ()=>{
    return {
      type:types.COMPANIES_HIDE_OLD,
    }
}

export const onInputChange = (e) => {
  console.log(e)
  const {name, value} = e.target;
  return {
    type: types.COMPANIES_INPUT_CHANGE,
    name,
    value
  }
};

export const clearFilter = () => {
  return {type: types.COMPANIES_CLEAR_FILTER}
};


export const fetchCompanies = (query, page) => {
  return async function (dispatch) {
    dispatch({type: types.COMPANIES_LOADING});
    try {
      const {data} = await axios.post('/api/companies/filter', query);
      dispatch({
        type: types.COMPANIES_FETCHED,
        data,
        page
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
};


export const toggleAdvancedSearch = () => {
  return {type: types.COMPANIES_TOGGLE_ADVANCED_SEARCH}
};

export const saveToFile = () => async (dispatch, getState) => {
  const {list} = getState().companies.companiesList;
  console.log(list)
  /* convert state to workbook */
  const data = list.map((company)=>{
      return [
        company.name + ' ' + company.type,
        company.region,
        company.address,
        JSON.stringify(company.categories).replace(/"/g, '').replace(/,/g, ' '),
        company.user? company.user.lastName : '',
        company.lastReport? company.lastReport.date : '',
        company.link? company.link :'',
        company.status,
        company.code,
      ]
  })
  const ws = XLSX.utils.aoa_to_sheet([
    ["Pavadinimas", "Regionas", "Adresas", 'Kategorijos', 'Priskirtas asmuo', 'Paskutinis irasas','Rekvizitai','Statusas', 'Įmones kodas'],
    ...data
  ]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, "imones.xlsx")
}





