import * as types from '../../types';
import axios from 'axios';
import setToken from '../../../utils/setToken';
import jwt from 'jsonwebtoken';

export function onInputChange(e) {
  return {
    type: types.LOGIN_FORM_INPUT_CHANGE,
    field: e.target.id,
    value: e.target.value
  }
}

export function onFormSubmit(e, data, history) {
  e.preventDefault();
  if (!data.email || !data.password) return {type: 'NONE'};

  return async function (dispatch) {
    // Set loading
    dispatch({
      type: types.LOGIN_FORM_LOADING,
    });
    const {email, password} = data;
    // Send login data
    try {
      const {data} = await axios.post('/api/auth/login', {email, password});
      setToken(data);
      const decodedToken = jwt.decode(data);
      dispatch({
        type: types.LOGIN_FORM_SUCCESS,
        user: decodedToken.data
      });
      history.push('/')
    }
      // Catch errors
    catch (e) {
      console.log(e);
      console.log(e.response);
      dispatch({
        type: types.LOGIN_FORM_ERROR,
        errors: {password: 'Neteisingi prisijungimo duomenys'}
      })
      setTimeout(()=>{
          dispatch({type:types.LOGIN_FORM_CLEAR_ERROR})
      },2000)
    }
  }
}