import moment from "moment";
import style from "./index.module.scss";
import React, {useEffect, useState} from "react";
import Table from "../ui/Table/Table";
import {withRouter} from 'react-router-dom';
import {months} from '../../utils/dates';
import {connect} from 'react-redux';
import * as actions from '../../store/dashboard/weekly/weeklyReports.actions';
import {showReport} from '../../store/dashboard/reporForm/reportForm.actions';
import Icon from "../ui/Icon/Icon";
import head from '../../assets/img/head-black.png';
import danger from '../../assets/img/danger.svg';
import t from '../../utils/translations';
import axios from "axios";

const WeeklyReportTable = (props) => {
  const {report, week, lang, userId} = props;

  const [comment, setComment] = useState({});

  const fetchComments = async () => {
    const {data} = await axios.get(`/api/comments/${userId}/${week}`);
    if (data) setComment(data)
  }
  useEffect(() => {
    fetchComments()
  }, [report])

  const onCommentChange = (e) => {
    setComment({...comment, message: e.target.value})
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if(!comment.message && !comment._id) return;
      const message = comment.message || '';
      comment._id ?
        axios.put('/api/comments', {message, userId, week}) :
        axios.post('/api/comments', {message, userId, week})
    }, 700)
    return () => {
      clearInterval(timer)
    }
  }, [comment.message])

  if (!report) return null;

  // // selected week's start and end days
  const startOfWeek = moment().startOf('isoWeek').subtract(week, 'week').format('D');
  const endOfWeek = moment().endOf('isoWeek').subtract(week, 'week').format('D');

  const startOfMonth = moment().startOf('isoWeek').subtract(week, 'week').format('M');
  const endOfMonth = moment().endOf('isoWeek').subtract(week, 'week').format('M');

  const weekDayNames = ['I', 'II', 'III', 'IV', 'V'];

  const days = [];
  for (let i = 0; i < 5; i++) {
    // push object with report types for each day
    days.push({phone: [], meeting: [], offer: [], comment: []})
  }

  // populate each day's objects with data
  report.forEach((item) => {
    const weekday = moment(item.date).isoWeekday() - 1;
    if (weekday <= 4) days[weekday][item.type].push(item);
  });


  // Create table column data with list of companies
  const getList = (arr, index) => {
    const listItems = arr.map((item, i) => {
      return (
        <li key={i}>
          <div onClick={() => props.showReport(item)}>
            {item.company && item.company.name}
            {item.state === 'canceled' &&
              <Icon icon='times' size='sm' className={style.canceled}/>}
            {item.state === 'sold' &&
              <img src={head} alt='sold' className={style.sold}/>}
            {item.state === 'active' &&
              <img src={danger} alt='sold' className={style.danger}/>}
          </div>
          <div className={style.comment}>
            {item.comment}
          </div>
        </li>
      )
    });
    return (
      <ul className={style.list} key={index}>{listItems}</ul>
    )
  };

  // Create table columns
  const tableRows = days.map((day, d) => {
    return (
      <div className={style.tableRow} key={d}>
        <h3 className={style.weekday}>{weekDayNames[d]}</h3>
        {getList(day.phone)}
        {getList(day.meeting)}
        {getList(day.offer)}
      </div>
    )
  });

  // Create input for comments only for admin
  const renderInput = () => {
    if (props.user.role !== 'admin'){
      return (
        <div className={style.commentReadOnly}>
          {comment.message}
        </div>
      )
    }
    return (
      <textarea className={style.commentInput} value={comment.message} onChange={onCommentChange}/>
    )
  }
  const resolution = window.innerWidth;
  return (
    <div className={style.tableContainer}>
      <div className={style.reportTable}>
        <div className={style.labels}>
          {resolution > 600 ?
            <>
              <span>{t.phone_calls[lang]}</span>
              <span>{t.meetings[lang]}</span>
              <span>{t.offers[lang]}</span>
            </> :
            <>
              <Icon icon='phone' className={style.icon}/>
              <Icon icon='deal' className={style.icon}/>
              <Icon icon='file' className={style.icon}/>
            </>}
        </div>
        <Table className={style.table}>
          {tableRows}
        </Table>
        <div className={style.dateRange}>
          {months[startOfMonth - 1]} {startOfWeek}d. - {months[endOfMonth - 1]} {endOfWeek}d.
        </div>
      </div>
      <div className={style.adminComments}>
        <h3 className={style.title}>Komentarai</h3>
        <Table className={style.comment}>
          {renderInput()}
        </Table>
      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    lang: state.settings.lang
  }
};
export default withRouter(connect(mapStateToProps, {
  ...actions,
  showReport
})((WeeklyReportTable)))
