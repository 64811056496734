import * as types from '../../types';
import axios from 'axios';
import setToken from '../../../utils/setToken';
import jwt from 'jsonwebtoken';
import {showError} from '../../utils';

export function toggleEditProfile(value, auth) {
  return {
    type: types.TOGGLE_EDIT_PROFILE,
    value,
    auth
  }
}

export function updateProfile(profile) {
  const {firstName, lastName, email, phone, image} = profile;
  const errors ={};
  if(firstName && firstName.length<3) errors.firstName = 'Vardas per trumpas';
  if(!firstName) errors.firstName = 'Neįrašytas vardas';
  if(lastName && lastName.length<3) errors.lastName = 'Pavardė per trumpa';
  if(!lastName) errors.lastName = 'Neįrašyta pavardė';
  if(email && email.length<3) errors.email = 'Neteisingas el. paštas';
  if(!email) errors.email = 'Neįrašytas el. paštas';
  if(Object.keys(errors).length>0){
    return {
      type:types.PROFILE_ERROR,
      errors
    }
  }
  return async function (dispatch) {
    dispatch({
      type:types.PROFILE_LOADING
    });
    try {
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('image', image);

      const {data} = await axios.post('/api/settings/profile', formData);
      // extract data from token to update profile photo
      setToken(data);
      const decoded = jwt.decode(data);
      dispatch({
        type: types.UPDATE_PROFILE,
        profile,
        image:decoded.data.image
      })
    } catch (e) {
      showError(dispatch, e)
    }
  }
}

export function onDrop(files){
  files[0].preview= URL.createObjectURL(files[0]);
  return {
    type: types.PROFILE_IMAGE_CHANGE,
    image:files[0]
  }
}

export function onProfileInputChange(e) {
  return {
    type: types.PROFILE_INPUT_CHANGE,
    value: e.target.value,
    field: e.target.id
  }
}



