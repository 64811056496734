import React, {useState} from 'react';
import Table from "../../components/ui/Table/Table";
import moment from 'moment';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Button from "../../components/ui/Button/Button";
import {Link} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {changeReminderDate, changeReminderStatus} from "../../store/reminders/reminders.actions";
import ConfirmationModal from "../../components/ui/ConfirmationModal/ConfirmationModal";
import Modal from "../../components/ui/Modal/Modal";
import {Calendar} from "react-calendar";

const cx = classNames.bind(styles);

export const Row = (props) => {
  const {comment, notifyAt, company, report, numberOfPostpones, hasSeen} = props.reminder;
  let ending = 'kartą';
  if (numberOfPostpones < 10 && numberOfPostpones > 1) ending = 'kartus';
  if (numberOfPostpones > 9) ending = 'kartų'
  return (
    <div className={cx('reminder')}>
      <div className={cx('date')}>{moment(notifyAt).format('YYYY-MM-DD')}</div>
      <Table className={cx('table')}>
        {company &&
          <Link to={`/company/${company._id}/info`} className={cx('company')}>
            <div>{company.name}</div>
          </Link>}
        {report &&
          <div className={cx('report')}>
            <div>{report.comment}</div>
          </div>}
        <div className={cx('row')}>
          <div className={cx('comment')}>{comment}</div>
        </div>
        <div className={cx('status')}>
          <div>
            {numberOfPostpones > 0 &&
              <span className={cx('changes')}>Pakeista data {numberOfPostpones} {ending}</span>}
          </div>
          <div>
            {hasSeen ? <span className={cx('done')}>Pažymetas kaip ivykdytas</span> :
              <span className={cx('active')}>Aktyvus</span>}
          </div>
        </div>
      </Table>
    </div>
  );
};
