import React from 'react';
import {withRouter} from 'react-router-dom';
import Icon from "../Icon/Icon";
import PropTypes from 'prop-types';
import style from './index.module.scss';

const ButtonBack = (props) => {
  const onClick = () => {
    props.onClick ? props.onClick() : props.history.goBack()
  };
  return (
    <div onClick={onClick}
         className={[style.back, props.className].join(' ')}>
      <Icon icon='chevron-left' className={style.icon}/>
    </div>
  )
};


ButtonBack.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withRouter(ButtonBack);
