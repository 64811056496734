import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import axios from "axios";
import setToken from "../../utils/setToken";
import {logoutUser} from '../../store/auth/user/user.actions';
import LoadingScreen from "../../layout/LoadingScreen/LoadingScreen";
import UserInfo from "../../layout/UserInfo/UserInfo";
import ErrorModal from "../../components/ui/ErrorModal/ErrorModal";
import style from "./index.module.scss";
import Admin from './Admin/Admin';
import Sales from "./Sales/Sales";

const Roles = (props) => {
  const {sideMenu,logoutUser} = props;

  useEffect(()=>{
    (async ()=>{
      //get refresh token on page reload
      if (process.env.NODE_ENV !== 'production') return;
      try {
        const {data} = await axios.get('/api/auth/refresh');
        setToken(data)
      } catch (e) {
        // if token is expired, logoutUser and redirect to login
        console.log(e.response);
        console.log('token expired!');
        logoutUser(props.history);
      }
    })()
    // eslint-disable-next-line
  },[]);

  if (!props.auth.user.firstName) return <Redirect to='/login'/>;

  const renderContent = () => {
    switch (props.auth.user.role) {
      case 'admin' :
        return (
          <React.Suspense fallback={<LoadingScreen/>}>
            <Admin/>
          </React.Suspense>
        );
      case 'sales':
        return (
          <React.Suspense fallback={<LoadingScreen/>}>
            <Sales/>
          </React.Suspense>
        );
      default :
        return null
    }
  };
  return (
    <React.Fragment>
      <UserInfo/>
      <ErrorModal/>
      <div className={sideMenu.closed ? style.wide : style.narrow}>
        {renderContent() }
      </div>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    auth: state.auth,
    sideMenu: state.sideMenu,
  }
};
export default connect(mapStateToProps, {logoutUser})(Roles);