import React, {useRef} from 'react';
import style from './index.module.scss';
import {useOutsideClick} from '../../../utils/useOutsideClick';
import PropTypes from 'prop-types';

export const MenuItem = (props)=>{
  return <p className={style.item} onClick={props.onClick}>{props.label}</p>
}

MenuItem.propTypes = {
  label:PropTypes.string.isRequired,
  onClick:PropTypes.func.isRequired,
}

const Menu = (props) => {
  const node = useRef(null);
  useOutsideClick(node, props.close)

  if(!props.show) return null;

  return (
    <div className={style.menu} ref={node}>
      {props.children}
    </div>
  );
};

Menu.propTypes = {
  close:PropTypes.func.isRequired,
  children:PropTypes.node,
  show:PropTypes.bool.isRequired,
}

export default Menu;
