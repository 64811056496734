import * as types from '../../types';
import axios from 'axios';
import {companiesWithCodes} from '../../../utils/comapany';
import {showError} from '../../utils';
import latinize from 'latinize';

export const onInputChange = (e) => {
  const {name, value} = e.target;
  return {
    type: types.COMPANY_FORM_INPUT_CHANGE,
    name,
    value
  }
};

export const fetchCompanyData = () => {
  return async (dispatch, getState) => {
    let name=latinize(getState().companies.companyForm.name)
      .toLowerCase()
      .replace(/ /g, "_")
      .trim();
    if(name.includes('/')){
      const names = name.split('/').filter(n=>n);
      name=names[names.length-1]
    }
    try{
      const {data} = await axios.get('/api/companies/rekvizitai/'+name);

      // Extract company data from response
      const code = data[data.indexOf('Įmonės kodas')+1];
      const address = data[data.indexOf('Adresas')+1];
      let region = address.split(' ');
      region=region[region.length-1];
      region=region.slice(0, 4).toLowerCase();
      const regions = getState().companies.regions;
      const [r] = regions.filter(reg=>reg.includes(region));

      console.log(regions, region);
      console.log(data[1]);
      console.log(companiesWithCodes.includes(data[1]));
      dispatch({
        name:data[0],
        type:types.COMPANY_FETCH_DATA,
        code:companiesWithCodes.includes(data[1]) ? code : '',
        companyType:data[1],
        address,
        link:'https://rekvizitai.vz.lt/imone/'+name,
        region:r
      })
    }catch (e) {
      console.log(e);
      console.log(e.response);
      dispatch({
        type:types.COMPANY_FETCH_DATA_ERROR,
        errors:{data:'Įmonė nerasta'}
      });
      setTimeout(()=>{
          dispatch({
            type:types.COMPANY_FETCH_DATA_ERROR,
            errors:{}
          })
      },7000)
    }
  }
}

export const onDrop = (files) => {
  files[0].preview = URL.createObjectURL(files[0]);
  return {
    type: types.COMPANY_UPLOAD_IMAGE,
    image: files[0]
  }
};

export const changeImage = (files, id) => {
  const formData = new FormData();
  formData.append('id', id);
  formData.append('image', files[0]);

  return async dispatch => {

    try {
      const {data} = await axios.post('/api/companies/upload', formData);
      dispatch({
        type: types.COMPANY_UPLOAD_IMAGE,
        image: data.image,
        id
      })
    } catch (e) {
      showError(dispatch, e)
    }
  };
};

export const addCategory = (e) => {
  return {
    type: types.COMPANY_ADD_CATEGORY,
    name: e.target.value
  }
};

export const removeCategory = (name) => {
  return {
    type: types.COMPANY_REMOVE_CATEGORY,
    name
  }
};

export const addContact = () => {
  return {
    type: types.COMPANY_ADD_CONTACT
  }
};

export const removeContact = (index) => {
  return {
    type: types.COMPANY_REMOVE_CONTACT,
    index
  }
};

export const onContactInputChange = (index, e) => {
  return {
    type: types.COMPANY_CONTACT_INPUT_CHANGE,
    index,
    name: e.target.name,
    value: e.target.value
  }
};

export const onFormSubmit = (company, history) => {
  const errors = {};
  if (!company.name) errors.name = 'Neįrašytas pavadinimas';
  if (!company.address) errors.address = 'Neįrašytas įmonės adresas';
  if (!company.type) errors.type = 'Nepasirinktas tipas';
  if (!company.code && companiesWithCodes.includes(company.type)) {
    errors.code = 'Neįrašytas įmonės kodas'
  }
  if (!company.region) errors.region = 'Nepasirinktas regionas';
  if (company.categories.length === 0) errors.categories = 'Nepriskirtos kategorijos';
  if (Object.keys(errors).length > 0) {
    window.scrollTo(0, 0);
    return {
      type: types.COMPANY_FORM_ERROR,
      errors
    }
  }

  return async function (dispatch) {
    dispatch({type: types.COMPANY_FORM_LOADING});

    try {

      // Send company data to server
      const {data} = await axios.post('/api/companies/create', company);

      // Wait for response and update company image
      console.log(data)
      if (company.image) {
        const formData = new FormData();
        // image must be appended at the end!
        console.log(company._id)
        formData.append('id', data._id);
        formData.append('image', company.image);
        await axios.post('/api/companies/upload', formData);
      }

      dispatch({
        type: types.COMPANY_SAVED,
        data
      });

      // Redirect on success
      history.push(`/company/${data._id}/info`)
    } catch (e) {
      if (e.response && e.response.data === 'dublicate') {
        dispatch({
          type: types.COMPANY_FORM_ERROR,
          errors: ({code: 'Įmonė jau įtraukta į sąrašą'})
        })
      } else {
        showError(dispatch, e)
      }
    }
  }
};


export function loadCompanyForm(company) {
  return {
    type: types.COMPANY_LOAD_FORM,
    company
  }
}

export function clearCompanyForm() {
  return {
    type: types.COMPANY_CLEAR_FORM
  }
}

export const deleteCompany = (company, history) => {
  return async function (dispatch) {
    try {
      await axios.delete(`/api/companies/${company._id}`);
      dispatch({
        type: types.COMPANY_DELETED,
        id: company._id
      });
      history.push('/companies/list')
    } catch (e) {
      showError(dispatch, e)
    }
  }
};

export const fetchCompany = (id) => async dispatch => {
  try {
    const {data} = await axios.get('/api/companies/' + id);
    dispatch({
      type: types.COMPANY_FETCHED,
      data
    })
  } catch (e) {
    showError(dispatch, e)
  }
};

export const assignDefaultUser = (user)=>{
    return {
      type:types.COMPANY_ASSIGN_DEFAULT_USER,
      user
    }
};