import * as types from '../../types';

export default (state=null,action)=>{
    switch (action.type){
      case types.USERS_FETCHED :
        return action.data;
      case types.USER_UPLOAD_IMAGE :
        return state.map((user)=>{
            return user._id===action.id ? {...user, image:action.image} : user
        })
      default : return state
    }
}