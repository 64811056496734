import React from 'react';
import {connect} from 'react-redux';
// Store
import * as actions from '../../../store/rent/rent.actions';
// Style
import style from './index.module.scss';

const state = {
  available: 'Laisvas',
  rented: 'Išnuomotas',
  broken: 'Sugędes',
  reserved: 'Rezervuotas',
}
const RentTable = (props) => {
  const {rent} = props;
  const items = rent.list.map((item, i) => {
    return (
      <tr
        className={style.item}
        key={item._id}
        onClick={() => props.openStatusCard(item)}
      >
        <td>
          <h5>{item.name}</h5>
        </td>
        <td>
          <p>{item.serialNr}</p>
        </td>
        <td>
          <p>{item.location}</p>
        </td>
        <td>
          <p>{item.person && item.person.firstName}</p>
        </td>
        <td>
          <h5>{state[item.state]}</h5>
        </td>
        <td>
          <p>2020-05-11</p>
        </td>
        <td>
          <p>2020-06-05</p>
        </td>
      </tr>
    )
  })
  return (
    <table className={style.table}>
      <thead/>
      <tr className={style.heading}>
        <th>Pavadinimas</th>
        <th>Serijos nr</th>
        <th>Sandelys</th>
        <th>Atsakingas zm.</th>
        <th>Busena</th>
        <th>Nuo</th>
        <th>Iki</th>
      </tr>
      {items}
    </table>
  );
};

const mapStateToProps = state => {
  return {
    rent: state.rent
  }
};
export default connect(mapStateToProps, actions)(RentTable);
