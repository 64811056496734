import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import primaryStyle from './primary.module.scss';
import secondaryStyle from './secondary.module.scss';
import primaryLight from './primary-light.module.scss';
import primaryHeading from './primary-heading.module.scss';
import Icon from "../Icon/Icon";


const Select = props => {
  let style;

  const node = useRef();


  const [list, toggleList] = useState(false);

  useEffect(() => {
    document.body.addEventListener('mousedown', closeList, false);
    return () => {
      document.body.removeEventListener('mousedown', closeList, false)
    }
  }, []);

  switch (props.type) {
    case 'secondary' :  style = secondaryStyle;
    break;
    case 'primary-light' :  style = primaryLight;
    break;
    case 'primary-heading' : style = primaryHeading;
    break;
    default : style=primaryStyle
  }

  const closeList = (e) => {
    if (!node.current.contains(e.target)) {
      toggleList(false)
    }
  };

  let selectedOption = '';


  const options = ['', ...props.options].map((option, i) => {

    /*
        Case options are [Strings] and value is String
     */
    if (typeof option === 'string') {
      if (option === props.value) selectedOption = option;
      return (
        <li onClick={() => {
              toggleList(!list);
              props.onChange({target: {name: props.name, value: option}})
            }}
            key={i} tabIndex={0}
            style={{textTransform: props.capitalize ? 'capitalize' : ''}}
            className={style.item}>
          {option}
        </li>
      )
    }

    /*
         Case options are [Objects]
     */
    if (typeof option === 'object') {

      // Case when options are [{label:String, value:Object}]
      // Value is Object
      if (typeof props.value === 'object' && JSON.stringify(option.value) === JSON.stringify(props.value)) {
        selectedOption = option.label
      }

      // Case when options are [{label:String, value:String}]
      // Value is String, Number or Boolean
      if ((typeof props.value === 'string' || typeof props.value === 'number' || typeof props.value === 'boolean') && option.value === props.value) {
        selectedOption = option.label
      }
      return (
        <li onClick={() => {
          toggleList(!list);
          props.onChange({target: {name: props.name, value: option.value}})
        }}
            style={{textTransform: props.capitalize ? 'capitalize' : ''}}
            key={i} tabIndex={0}
            className={style.item}>
          {option.label}
        </li>
      )
    }

    return null

  });



  return (
    <div className={[style.select, props.className].join(' ')}
         onClick={() => toggleList(!list)}
         tabIndex={0}
         ref={node}>

      {!props.disabled &&
      <Icon className={style.chevron}
            style={{zIndex: list ? 15 : ''}}
            icon={list
              ? "chevron-up"
              : "chevron-down"}>
      </Icon>
      }

      {props.error ?
        <div className={style.error}>{props.error}</div> :
        props.label && <div className={style.label}>{props.label}</div>
      }


      {props.placeholder && !props.value &&
      <span className={style.placeholder}>
        {props.placeholder}
      </span>
      }

      <div className={style.value}
           style={{textTransform: props.capitalize ? 'capitalize' : ''}}>
        {selectedOption}
      </div>

      <ul tabIndex={0}
          style={{
            zIndex: list ? 14 : '',
            border: list ? '' : 'none'
          }}
          className={[style.options].join(' ')}>
        {list && options}
      </ul>
    </div>
  );
};

/*
 * name - field variable name
 * label - not selected field text
 * value - selected field value
 * error - display error instead of label
 * onChange - function to execute on select, returns e.target.value
 * options - array of {value, label} or Strings,
 * className - additional className to modify css properties
 */

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  capitalize: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled:PropTypes.bool,
  options: PropTypes.array.isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Select;