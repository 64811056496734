import React, {useEffect, useState} from 'react';
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../store/users/users.actions';
import Select from "../../../components/ui/Select/Select";
import InputPrimary from "../../../components/ui/InputPrimary/InputPrimary";
import Table from "../../../components/ui/Table/Table";
import TextArea from "../../../components/ui/TextArea/TextArea";
import Dropzone from "react-dropzone";
import {userStatusOptions, userRolesOptions} from '../../../utils/user';
import Loader from "../../../components/ui/Loader/Loader";
import Modal from "../../../components/ui/Modal/Modal";
import Button from "../../../components/ui/Button/Button";
import Heading2 from "../../../components/ui/Heading2/Heading2";
import Heading1 from "../../../components/ui/Heading1/Heading1";

const UpdateUser = props => {

  useEffect(() => {
    // if users list is loaded, filter user and load form data
    (async () => {
      if (!props.users) props.fetchUsers();
      if (props.users) {
        const [user] = props.users.filter(user => user.email === props.match.params.email);
        if (user) props.loadUserForm(user);
      }
    })();
    return () => {
      props.clearUserForm()
    }
    // eslint-disable-next-line
  }, [props.users]);


  const [resetModal, toggleResetModal] = useState(false);
  const [deleteModal, toggleDeleteModal] = useState(false);


  if (!props.users) return <Loader loading={true}/>;

  if (!props.user.email) return <Heading2 className={style.notFound}>Darbuotojas nerastas</Heading2>;


  return (
    <div className={style.update}>

      <Modal visible={resetModal}
             hideModal={() => toggleResetModal(false)}>
        <Heading2 className={style.modal_heading}>Atstatyti slaptažodį?</Heading2>
        <p className={style.modal_text}>
          Laikinas slaptažodis bus darbuotojo el. paštas
        </p>
        <Button className={style.modal_button}
                onClick={() => {
                  props.resetPassword(props.user);
                  toggleResetModal(false)
                }} type='primary'>
          Taip
        </Button>
        <Button className={style.modal_button}
                onClick={() => toggleResetModal(false)} type='secondary'>
          Ne
        </Button>
      </Modal>

      <Modal visible={deleteModal}
             hideModal={() => toggleDeleteModal(false)}>
        <Heading2 className={style.modal_heading}>Ištrinti darbuotoją?</Heading2>
        <InputPrimary name='confirmCode'
                      label='Saugos kodas'
                      type='password'
                      error={props.user.errors.confirmCode}
                      className={style.modal_input}
                      onChange={props.onInputChange}/>
        <Button className={style.modal_button}
                onClick={() => props.onDeleteUser(props.user, props.history)}
                type='primary'>
          Taip
        </Button>
        <Button className={style.modal_button}
                onClick={() => toggleDeleteModal(false)}
                type='secondary'>
          Ne
        </Button>
      </Modal>

      <Heading1>Redaguoti</Heading1>
      <Table className={style.table}>
        <Dropzone
          accept="image/*"
          onDrop={files => props.onDrop(files, props.user._id)}>
          {({getRootProps, getInputProps}) => (
            <div style={{backgroundImage: props.user.image && `url('/images/${props.user.image}')`}}
                 className={style.photo}
                 {...getRootProps()}>
              <input {...getInputProps()} />
              <p className={style.photo_text}>Įkelti nuotrauką</p>
            </div>
          )}
        </Dropzone>
        <form onSubmit={(e) => props.createUser(e, props.user, props.history)}>
          <InputPrimary
            name='firstName'
            label='Vardas'
            type='text'
            error={props.user.errors.firstName}
            value={props.user.firstName}
            autoComplete={false}
            className={style.input}
            capitalize={true}
            onChange={props.onInputChange}/>
          <InputPrimary
            name='lastName'
            label='Pavarde'
            type='text'
            capitalize={true}
            error={props.user.errors.lastName}
            value={props.user.lastName}
            autoComplete={false}
            className={style.input}
            onChange={props.onInputChange}/>
          <InputPrimary
            name='email'
            label='Paštas'
            type='text'
            lowercase
            value={props.user.email}
            error={props.user.errors.email}
            autoComplete={false}
            className={style.input}
            onChange={props.onInputChange}/>
          <InputPrimary
            name='phone'
            label='Tel. nr.'
            type='text'
            value={props.user.phone}
            error={props.user.errors.phone}
            autoComplete={false}
            className={style.input}
            onChange={props.onInputChange}/>
          <div className={style.date}>
            {props.user.errors.birthday?
              <span>{props.user.errors.birthday}</span>:
              <h4>Gimimo data</h4>
            }
            <input type="text"
                   name='year'
                   value={props.user.year}
                   onChange={props.onInputChange}
                   placeholder='MMMM'/>
            <input type="text"
                   name='month'
                   value={props.user.month}
                   onChange={props.onInputChange}
                   placeholder='MM'/>
            <input type="text"
                   name='day'
                   value={props.user.day}
                   onChange={props.onInputChange}
                   placeholder='DD'/>
          </div>
          <Select
            name='role'
            className={style.select}
            label='pareigos'
            value={props.user.role}
            error={props.user.errors.role}
            options={userRolesOptions}
            onChange={props.onInputChange}/>
          <Select
            name='status'
            className={style.select}
            label='būsena'
            value={props.user.status}
            error={props.user.errors.status}
            options={userStatusOptions}
            onChange={props.onInputChange}/>
          <TextArea
            name='desc'
            label='Aprašymas ir komentarai'
            value={props.user.desc}
            className={style.textarea}
            onChange={props.onInputChange}/>
          <Button
            loading={props.user.loading}
            type='primary'
            className={style.btn}>
            Išsaugoti
          </Button>
        </form>
      </Table>
      <Table className={style.table}>
        {props.user.resetSuccess ?
          <h2>Slaptažodis atstatytas</h2> :
          <h2>Laikinas slaptažodis bus varototojo el. paštas</h2>
        }
        {!props.user.resetSuccess &&
        <Button
          loading={props.user.reseting}
          type='primary'
          onClick={() => toggleResetModal(true)}
          className={style.btn}>
          {props.resetSuccess ? 'Slaptažodis atstatytas' : 'Atstatyti slaptažodį'}
        </Button>
        }
      </Table>
      <Table className={style.table}>
        <h2>Ištrinti vartotoja su visais jo įrašais</h2>
        <Button
          loading={props.user.deleting}
          type='secondary'
          onClick={() => toggleDeleteModal(true)}
          className={[style.btn, style.delete].join(' ')}>
          Ištrinti
        </Button>
      </Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.users.usersList,
    user: state.users.userForm
  }
};
export default connect(mapStateToProps, actions)(UpdateUser);