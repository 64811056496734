import React, {useEffect} from 'react';
import Heading1 from "../../../components/ui/Heading1/Heading1";
import style from './index.module.scss';
import {connect} from 'react-redux';
import * as actions from '../../../store/dashboard/offers/offers.actions';
import {fetchCategories} from '../../../store/companies/categories/categories.actions';
import {fetchRegions} from '../../../store/companies/regions/regions.actions';
import {fetchUsers} from '../../../store/users/users.actions';
import Loader from "../../../components/ui/Loader/Loader";
import SearchBar from "./SearchBar";
import ReportCard from "../../../components/ReportCard";
import debounce from "lodash/debounce";

const Offers = (props) => {
  const {offers} = props;
  const {fields, skip} = offers;

  useEffect(() => {
    props.fetchOffers()
    // eslint-disable-next-line
  }, [fields,skip]);


  const scroll = debounce(() => {
    if (props.offers.stopScroll) return;
    const body = document.body,
      html = document.documentElement;

    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);

    if (window.scrollY + window.innerHeight * 1.2 > height && !offers.loading) {
      props.setSkip();
    }
  }, 500);


  useEffect(() => {

    document.addEventListener('scroll', scroll);

    return () => {
      document.removeEventListener('scroll', scroll);
    }

    // eslint-disable-next-line
  }, [scroll]);


  const renderOffers = () => {
    if (!offers.list) return null;
    return offers.list.map((offer, i) => {
      return <ReportCard key={i} report={offer}/>
    })
  };

  return (
    <div className={style.offersReport}>
      <Heading1>Komerciniai pasiūlymai</Heading1>
      <SearchBar/>
      <Loader loading={props.offers.loading} className={style.loader}/>
      {renderOffers()}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    offers: state.dashboard.offers,
    regions: state.companies.regions,
    categories: state.companies.categories,
    users: state.users.usersList
  }
};

export default connect(mapStateToProps, {
  ...actions,
  fetchCategories,
  fetchRegions,
  fetchUsers
})(Offers);
