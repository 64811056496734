import React from 'react';
import style from './index.module.scss';
import PropTypes from 'prop-types';

const Modal = (props) => {

  if(!props.visible) return null;
  return (
    <React.Fragment>
      <div className={style.backdrop} onClick={props.hideModal}/>
      <div className={[style.modal, props.className].join(' ')}>
        {props.children}
      </div>
    </React.Fragment>
  );
};

Modal.propTypes ={
  visible:PropTypes.bool.isRequired,
  children:PropTypes.node.isRequired,
  hideModal:PropTypes.func.isRequired,
  className:PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Modal;